import { jbColors } from './jbColors';

export const jbComponentPalette = Object.freeze({
    Blue: jbColors.royalBlue[100],
    LightBlue: jbColors.royalBlue[40],
    LightGrey: jbColors.black[20],
    MediumGrey: jbColors.black[60],
    DarkGrey: jbColors.black[80],
    Black: jbColors.black[100],
    White: '#FFFFFF',
});

export const palette = {
    primary: {
        light: jbComponentPalette.LightBlue,
        main: jbComponentPalette.Blue,
    },
    secondary: {
        main: jbComponentPalette.White,
        dark: jbComponentPalette.White,
        contrastText: jbComponentPalette.Blue,
    },
    text: {
        primary: jbComponentPalette.DarkGrey,
    },
};
