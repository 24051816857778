import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { Button, Paper, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { generatePath, useNavigate } from 'react-router-dom';
import { Paths } from '../../routing/paths';
import { PageHeader } from '../../components/page-header';
import { useMailTemplates } from '../../api';
import { RequestState } from '../../api/types';
import { LoadingSpinner } from '../../components/loading-spinner';

export const MailTemplateOverview: React.FC = () => {
    const { requestState, responseStatusCode, data: mailTemplates } = useMailTemplates();

    const navigate = useNavigate();

    const onEdit = useCallback(
        (id: string) => {
            navigate(generatePath(Paths.MAIL_TEMPLATE_EDIT, { id }));
        },
        [navigate]
    );

    const tableContent = useMemo(() => {
        if(requestState === RequestState.LOADING || requestState === RequestState.IDLE) {
            return <LoadingSpinner />
        }
        if(requestState === RequestState.ERROR) {
            return <div>
                { responseStatusCode === 401 && <span className="error">Unauthorized</span> }
                { responseStatusCode !== 401 && <span className="error">An unexpected error occurred while loading the data.</span> }
            </div>;
        }

        const sortedMailTemplates = mailTemplates?.sort((a, b) => a.order - b.order);

        return <>
            { sortedMailTemplates?.map(mailTemplate => <TableRow key={mailTemplate.id}>
                <TableCell>{mailTemplate.event}</TableCell>
                <TableCell>
                    <Button onClick={() => onEdit(mailTemplate.id)} startIcon={<Edit />}>Edit</Button>
                </TableCell>
            </TableRow>) }
        </>
    }, [requestState, responseStatusCode, mailTemplates, onEdit]);

    return <div className="mail-template-overview">
        <PageHeader title="Mail Templates" />
        <TableContainer className="mail-template-overview__table" component={Paper}>
            <Table aria-label="mail templates table">
                <TableHead>
                    <TableRow>
                        <TableCell>Event</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                {tableContent}
            </Table>
        </TableContainer>
    </div>;
}