export type Order = 'asc' | 'desc';

export function descComparator<T>(a: T, b: T, sortKeys: Array<keyof T>): number {
    if(sortKeys.length === 0) {
        return 0;
    }

    if (b[sortKeys[0]] < a[sortKeys[0]]) {
        return -1;
    }
    if (b[sortKeys[0]] > a[sortKeys[0]]) {
        return 1;
    }

    return descComparator(a, b, sortKeys.slice(1));
}

export function getComparator<T>(
    sortOrder: Order,
    sortKeys: Array<keyof T>,
): (
    a: T,
    b: T,
) => number {
    return sortOrder === 'desc'
        ? (a, b) => descComparator<T>(a, b, sortKeys)
        : (a, b) => -descComparator<T>(a, b, sortKeys);
}