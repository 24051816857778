import React from 'react';
import { AuthProviderProps } from 'react-oidc-context';
import { useConfig } from '../../api';
import { RequestState } from '../../api/types';

interface IAppConfigProps {
    children: (authProviderProps: AuthProviderProps, version: string) => React.ReactNode;
}

export const AppConfig: React.FC<IAppConfigProps> = ({ children }) => {
    const { requestState, data: config } = useConfig();

    if (requestState === RequestState.IDLE || requestState === RequestState.LOADING) {
        return <div className="content">Loading...</div>;
    }

    if (requestState === RequestState.ERROR || !config?.authority) {
        return <div className="content"><span className="error">An unexpected error occurred while initializing the application</span></div>;
    }

    // http://docs.identityserver.io/en/latest/quickstarts/4_javascript_client.html
    const authProviderProps: AuthProviderProps = {
        authority: config.authority,
        client_id: config.clientId,
        resource: process.env.NODE_ENV === 'development' ? undefined : config.resource, // resource is needed for adfs
        scope: 'openid offline',
        redirect_uri: process.env.NODE_ENV === 'development' ? 'http://localhost:3000/login-callback' : `${window.location.origin}/login-callback`,
        post_logout_redirect_uri: process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : window.location.origin,
    };

    return <>
        {children(authProviderProps, config.version)}
    </>;
}