import React, { useCallback, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import {
    Button,
    Checkbox,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TableSortLabel
} from '@mui/material';
import { TableCellProps } from '@mui/material/TableCell/TableCell';
import { Edit } from '@mui/icons-material';
import { getComparator, Order } from '../../utils';
import { IOffering, useOfferings } from '../../api';
import { RequestState } from '../../api/types';
import { Paths } from '../../routing/paths';
import { formatIsoDate, formatNumber } from '../../utils';
import { LoadingSpinner } from '../loading-spinner';

interface IHeadCell {
    id: keyof IOffering;
    label: string;
    align?: TableCellProps['align'];
}

const headerCells: IHeadCell[] = [
    { id: 'fundName', label: 'Fund' },
    { id: 'fundShortName', label: 'Fund Short Name' },
    { id: 'sortId', label: 'Sort ID', align: 'right' },
    { id: 'type', label: 'Type of Fund' },
    { id: 'closingDate', label: 'Close for Subscription' },
    { id: 'currency', label: 'Currency' },
    { id: 'capacity', label: 'Target fund capacity', align: 'right' },
    { id: 'reserve', label: 'Reserve for fees and expenses (%)', align: 'right' },
    { id: 'reserveDecimal', label: 'Total capacity incl. Reserve', align: 'right' },
    { id: 'raised', label: 'Raised', align: 'right' },
    { id: 'remainingCapacity', label: 'Remaining capacity', align: 'right' },
    { id: 'isActive', label: 'Active' },
];

const defaultSortKeys: Array<keyof IOffering> = ['isActive', 'sortId'];

interface IOfferingsTableProps {
    hideInactive?: boolean;
}

export const OfferingsTable: React.FC<IOfferingsTableProps> = ({ hideInactive }) => {
    const navigate = useNavigate();

    const { requestState, data, responseStatusCode } = useOfferings();

    const offerings = useMemo(() => hideInactive ? data?.filter(offering => offering.isActive) : data, [data, hideInactive]);

    const [sortKeys, setSortKeys] = useState<Array<keyof IOffering>>(defaultSortKeys);
    // desc so that the active offerings are displayed first
    const [sortOrder, setSortOrder] = useState<Order>('desc');

    const onRequestSort = useCallback((newSortKey: keyof IOffering) => {
        const newOrder: Order = (sortKeys.length && sortKeys[0] === newSortKey && sortOrder === 'asc') ? 'desc' : 'asc';

        setSortKeys([newSortKey, 'isActive', 'sortId']);
        setSortOrder(newOrder);
    }, [sortKeys, setSortKeys, sortOrder, setSortOrder]);

    const tableData = useMemo(() => {
        return offerings?.slice().sort(getComparator<IOffering>(sortOrder, sortKeys));
    }, [offerings, sortOrder, sortKeys]);

    const onEdit = useCallback(
        (id: string) => {
            navigate(generatePath(Paths.OFFERING_EDIT, { id }));
        },
        [navigate]
    );

    const bodyContent = useMemo(() => {
        if (requestState === RequestState.IDLE || requestState === RequestState.LOADING) {
            return (
                <TableRow>
                    <TableCell colSpan={7}>
                        <LoadingSpinner />
                    </TableCell>
                </TableRow>
            );
        } else if (requestState === RequestState.ERROR) {
            return (
                <TableRow>
                    <TableCell colSpan={7}>
                        <>
                            { responseStatusCode === 401 && <span className="error">Unauthorized</span> }
                            { responseStatusCode !== 401 && <span className="error">An unexpected error occurred while loading the data.</span> }
                        </>
                    </TableCell>
                </TableRow>
            );
        }

        if(!tableData?.length) {
            return (
                <TableRow>
                    <TableCell colSpan={7}>No offerings found</TableCell>
                </TableRow>
            );
        }

        return (
            tableData.map((offering) => (
                <TableRow key={offering.fundName}>
                    <TableCell scope="row" sortDirection="asc">{offering.fundName}</TableCell>
                    <TableCell>{offering.fundShortName}</TableCell>
                    <TableCell align="right">{offering.sortId}</TableCell>
                    <TableCell>{offering.type}</TableCell>
                    <TableCell>
                        {offering.closingDate ? formatIsoDate(offering.closingDate, undefined, { locale: 'de' }) : ''}
                    </TableCell>
                    <TableCell>{offering.currency}</TableCell>
                    <TableCell align="right">{formatNumber(offering.capacity ?? 0)}</TableCell>
                    <TableCell align="right">{offering.reserve}%</TableCell>
                    <TableCell align="right">{formatNumber(offering.reserveDecimal ?? 0)}</TableCell>
                    <TableCell align="right">{formatNumber(offering.raised ?? 0)}</TableCell>
                    <TableCell align="right">{formatNumber(offering.remainingCapacity ?? 0)}</TableCell>
                    <TableCell><Checkbox disabled={true} checked={offering.isActive} /></TableCell>
                    <TableCell>
                        <Button onClick={() => onEdit(offering.id)} startIcon={<Edit />}>
                            Edit
                        </Button>
                    </TableCell>
                </TableRow>
            ))
        );
    }, [tableData, requestState, onEdit, responseStatusCode]);

    const tableHeader = useMemo(() => {
        return (
            <TableHead>
                <TableRow>
                    {headerCells.map(cell => (
                        <TableCell key={cell.id} align={cell.align}>
                            <TableSortLabel
                                active={sortKeys.length > 0 && sortKeys[0] === cell.id}
                                direction={sortOrder}
                                onClick={() => onRequestSort(cell.id)}
                            >
                                {cell.label}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    <TableCell>Actions</TableCell>
                </TableRow>
            </TableHead>
        );
    }, [sortKeys, sortOrder, onRequestSort]);

    return (
        <TableContainer className="offerings-table" component={Paper}>
            <Table aria-label="offerings table">
                {tableHeader}
                <TableBody>{bodyContent}</TableBody>
            </Table>
        </TableContainer>
    );
};
