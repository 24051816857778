import { jbComponentPalette } from '../palette';

export const MuiButton = {
    defaultProps: {
        variant: 'contained' as const,
    },
    styleOverrides: {
        root: {
            borderRadius: 'initial' as const,
            fontWeight: 700,
            fontSize: 13,
            lineHeight: 1,
            textDecoration: 'none',
            outline: 'none',
            textAlign: 'center' as const,
            verticalAlign: 'middle',
            minWidth: 120,
            padding: '10px 25px',
        },
        contained: {
            boxShadow: 'none',
            '&:hover': {
                boxShadow: 'none',
                textDecoration: 'none',
            },
            '&:active': {
                boxShadow: 'none',
            },
            '&:disabled': {
                backgroundColor: jbComponentPalette.LightGrey,
                border: `1px solid ${jbComponentPalette.LightGrey}`,
                color: jbComponentPalette.White,
            },
        },
        containedPrimary: {
            border: `1px solid ${jbComponentPalette.Blue}`,
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: jbComponentPalette.White,
                color: jbComponentPalette.Blue,
                border: `1px solid ${jbComponentPalette.Blue}`,
            },

            '&:active': {
                backgroundColor: jbComponentPalette.White,
                color: jbComponentPalette.Blue,
            },
        },
        containedSecondary: {
            border: `1px solid ${jbComponentPalette.LightGrey}`,
            color: jbComponentPalette.Black,
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: jbComponentPalette.LightBlue,
                color: jbComponentPalette.White,
                border: `1px solid ${jbComponentPalette.LightBlue}`,
                textDecoration: 'none',
                boxShadow: 'none',
            },
            '&:active': {
                backgroundColor: jbComponentPalette.Blue,
                color: jbComponentPalette.White,
            },
        },
        /* Styles applied to the root element if `size="large"` and `variant="contained"`. */
        containedSizeLarge: {
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 20,
            paddingRight: 20,
            fontSize: 13,
            height: 50,
            width: 166,
            textTransform: 'uppercase' as const,
        },
    },
};
