import { IMailTemplate } from '../../domain';
import { IGetRequestResponse } from '../../types';
import { fetchMailTemplates } from '../../api-calls';
import { useAxiosGetRequest } from '../use-axios-request';

interface IMailTemplateResponse extends IGetRequestResponse<IMailTemplate[]> {}

export const useMailTemplates = (): IMailTemplateResponse => {
    return useAxiosGetRequest<IMailTemplate[]>(fetchMailTemplates);
};
