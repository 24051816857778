import * as React from 'react';
import { useCallback } from 'react';
import { useFileUpload } from '../../api';
import { RequestState } from '../../api/types';
import { PageHeader } from '../../components/page-header';
import { LoadingSpinner } from '../../components/loading-spinner';
import { FileUpload } from '../../components/file-upload';

export const BlacklistPage: React.FC = () => {
    const { requestState: fileUploadRequestState, create: fileUpload, responseStatusCode: uploadResponseStatusCode } = useFileUpload();

    const onUpload = useCallback((files: File[]) => {
        // https://docs.microsoft.com/en-us/aspnet/core/mvc/models/file-uploads?view=aspnetcore-6.0#match-name-attribute-value-to-parameter-name-of-post-method
        const formData = new FormData();

        files.forEach((file) => {
            formData.append('files', file, file.name);
        });

        fileUpload(formData);
    }, [fileUpload]);

    return <div className="Blacklist">
        <PageHeader title="Blacklist" />
        <FileUpload accept=".xls,.xlsx" disabled={fileUploadRequestState === RequestState.LOADING} maxSize={10485760} onUpload={onUpload} />
        <div>
            {fileUploadRequestState === RequestState.LOADING && (
                <>
                    <span>Uploading files...</span>
                    <LoadingSpinner />
                </>
            )}
            {fileUploadRequestState === RequestState.ERROR && (
                <>
                    { uploadResponseStatusCode === 401 && <p className="error">Unauthorized</p> }
                    { uploadResponseStatusCode !== 401 && <p className="error">An unexpected error occurred while uploading files.</p> }
                </>
            )}
            {fileUploadRequestState === RequestState.SUCCESS && <p>Uploaded files successfully</p>}
        </div>
    </div>;
}