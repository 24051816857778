import { IMailTemplate } from '../../domain';
import { updateMailTemplate } from '../../api-calls';
import { IPutRequestResponse } from '../../types';
import { useAxiosPutRequest } from '../use-axios-request';

interface IUpdateMailTemplateResponse extends IPutRequestResponse<IMailTemplate> {}

export const useUpdateMailTemplate = (): IUpdateMailTemplateResponse => {
    return useAxiosPutRequest<IMailTemplate>(updateMailTemplate);
};
