import { IReviewSettings } from '../../domain';
import { IGetRequestResponse } from '../../types';
import { fetchReviewSettings } from '../../api-calls';
import { useAxiosGetRequest } from '../use-axios-request';

interface IReviewSettingsResponse extends IGetRequestResponse<IReviewSettings> {}

export const useReviewSettings = (): IReviewSettingsResponse => {
    return useAxiosGetRequest<IReviewSettings>(fetchReviewSettings);
};
