import { ISettings } from '../../domain';
import { IPutRequestResponse } from '../../types';
import { updateSettings } from '../../api-calls';
import { useAxiosPutRequest } from '../use-axios-request';

interface IUpdateSettingsResponse extends IPutRequestResponse<ISettings> {}

export const useUpdateSettings = (): IUpdateSettingsResponse => {
    return useAxiosPutRequest<ISettings>(updateSettings);
};
