import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Paper } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import { fileSizeValidator } from '../../utils';

/**
 * accept: The value must be a comma-separated list of unique content type specifiers. See https://react-dropzone.js.org/#section-accepting-specific-file-types
 * maxSize: max file size in bytes
 * onUpload: callback that uploads files
 */
interface IFileUploadProps {
    accept?: string | string[];
    disabled?: boolean;
    maxSize?: number;
    singleFile?: boolean;
    onUpload: (files: File[]) => void;
}

export const FileUpload: React.FC<IFileUploadProps> = ({ accept, disabled, maxSize, singleFile, onUpload }) => {
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isDragActive,
    } = useDropzone({ accept, validator: maxSize != null ? fileSizeValidator(maxSize) : undefined });
    const handleUpload = useCallback(() => {
        onUpload(acceptedFiles);
    }, [acceptedFiles, onUpload]);

    const acceptedFilesList = useMemo(() => {
        return (
            <ul>
                { acceptedFiles.map((file, idx) => <li key={`accepted-${idx}`}>{file.name}</li>) }
            </ul>
        );
    }, [acceptedFiles]);

    const rejectedFilesList = useMemo(() => {
        return (
            <ul>
                {
                    fileRejections.map((fileRejection, idx) => {
                        const filename = fileRejection.file.name;
                        const errors = fileRejection.errors.map((error) => error.message).join(' ; ');

                        return <li key={`rejected-${idx}`}>{filename} (<span className="error">{errors}</span>)</li>
                    })
                }
            </ul>
        )
    }, [fileRejections])

    return (
        <div className="file-upload">
            <Paper {...getRootProps({
                'aria-label': 'drag and drop area',
                className: 'file-upload__drag-and-drop',
            })}>
                <input {...getInputProps()} />
                <div className="file-upload__drag-and-drop__content">
                    <CloudUpload />
                    {
                        isDragActive ?
                            <p>Drop files here ...</p> :
                            <p>Drag 'n' drop some files here, or click to select files</p>
                    }
                </div>
            </Paper>
            {(acceptedFiles.length > 0 || fileRejections.length > 0) &&
                <div className="file-upload__summary">
                    {acceptedFiles.length > 0 && (
                        <>
                            <p><b>Accepted</b></p>
                            {acceptedFilesList}
                        </>
                    )}
                    {singleFile && acceptedFiles.length > 1 && (
                        <>
                            {/* TODO: proper single file upload handling */}
                            <p className="error"><i><b>Note:</b> only one file will be processed</i></p>
                        </>
                    )}
                    {fileRejections.length > 0 && (
                        <>
                            <p><b>Rejected</b></p>
                            {rejectedFilesList}
                        </>
                    )}
                </div>
            }
            <Button
                className="file-upload__upload-button"
                disabled={disabled || acceptedFiles.length === 0 || fileRejections.length > 0}
                onClick={handleUpload}
            >Upload</Button>
        </div>
    );
}