import axios, { AxiosResponse } from 'axios';
import { generatePath } from 'react-router-dom';
import { IRequestOptions } from '../types';
import { IReviewSettings } from '../domain';
import { IReviewSettingsConfiguration } from '../domain/review-settings-configuration';

enum Url {
    REVIEW_SETTINGS = '/review-settings',
    REVIEW_SETTINGS_CONFIG = '/review-settings/configuration'
}

export const fetchReviewSettingsConfiguration: (options?: IRequestOptions<IReviewSettingsConfiguration>) => Promise<AxiosResponse<IReviewSettingsConfiguration>> = (options) => {
    return axios.get<IReviewSettingsConfiguration>(generatePath(Url.REVIEW_SETTINGS_CONFIG));
};

export const fetchReviewSettings: (options?: IRequestOptions<IReviewSettings>) => Promise<AxiosResponse<IReviewSettings>> = (options) => {
    return axios.get<IReviewSettings>(generatePath(Url.REVIEW_SETTINGS));
};

export const updateReviewSettings: (settings: IReviewSettings, options?: IRequestOptions) => Promise<AxiosResponse<IReviewSettings>> = (
    settings
) => {
    return axios.put<IReviewSettings>(Url.REVIEW_SETTINGS, settings);
};