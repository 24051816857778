import axios, { AxiosResponse } from 'axios';
import { generatePath } from 'react-router-dom';
import { IRequestOptions } from '../types';
import { IGenerateTestDataAmount } from '../domain';

enum Url {
    DEV = '/dev',
    RESET = '/dev/reset',
    GENERATE_TEST_DATA = '/dev/generate-test-data',
}

export const resetDev: (options?: IRequestOptions) => Promise<AxiosResponse<any>> = (options) => {
    return axios.post(generatePath(Url.RESET));
};

export const generateTestData: (amount: Partial<IGenerateTestDataAmount>, options?: IRequestOptions) => Promise<AxiosResponse<any>> = (amount, options) => {
    return axios.post<IGenerateTestDataAmount>(generatePath(Url.GENERATE_TEST_DATA), amount);
};