import { DateTime } from 'luxon';
import { LocaleOptions } from 'luxon/src/datetime';

/**
 * https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 */
interface INumberFormatOptions {
    minimumIntegerDigits?: number;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
}

/**
 * format a number according to Intl.NumberFormat
 *
 * @param number
 * @param options
 */
export const formatNumber = (number: number, options?: INumberFormatOptions) => {
    return new Intl.NumberFormat(undefined, options).format(number);
};

/**
 * format a currency according to Intl.NumberFormat
 *
 * @param amount
 * @param currency
 * @param options
 */
export const formatCurrency = (amount: number, currency: string, options?: INumberFormatOptions) => {
    // undefined means the implementation's default locale will be used.
    return new Intl.NumberFormat(undefined, {
        ...options,
        style: 'currency',
        currency,
    }).format(amount);
};

/**
 *
 * @param date luxon DateTime
 * @param format date format - see https://github.com/moment/luxon/blob/master/docs/formatting.md#presets
 * @param options luxon LocaleOptions
 */
export const formatDate = (date: DateTime, format?: Intl.DateTimeFormatOptions, options?: LocaleOptions) => {
    return date.toLocaleString(format ?? DateTime.DATE_SHORT, options);
};

/**
 *
 * @param isoDate date in iso format
 * @param format date format - see https://github.com/moment/luxon/blob/master/docs/formatting.md#presets
 * @param options luxon LocaleOptions
 */
export const formatIsoDate = (isoDate: string, format?: Intl.DateTimeFormatOptions, options?: LocaleOptions) => {
    return formatDate(DateTime.fromISO(isoDate), format, options);
};
