import React, { useCallback } from 'react';
import { DateTime } from 'luxon';
import { ValidationErrors } from 'final-form';
import { Form, Field } from 'react-final-form';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { IOffering, FundType } from '../../api';

export interface IOfferingFormValues {
    fundName: string;
    fundShortName: string;
    sortId: string;
    closingDate: DateTime;
    capacity: number;
    currency: string;
    reserve: number;
    isActive: boolean;
    type: FundType;
    subject: string;
    htmlBody: string;
}

interface IOfferingFormProps {
    offering?: IOffering;
    onSubmit: (values: IOfferingFormValues) => void;
}

// the component shouldn't be re-rendered when the form is submitted. Therefor this component is memoized
// todo: after editing and submitting an existing offering, the pristine state doesn't change. consequence of the memo. should be rethought.
export const OfferingForm = React.memo<IOfferingFormProps>(({ offering, onSubmit }) => {
    const onValidate = useCallback((values: any): ValidationErrors => {
        const errors: ValidationErrors = {};

        // required validation checks
        if (!values.fundName) {
            errors.fundName = 'Required';
        }
        if (!values.fundShortName) {
            errors.fundShortName = 'Required';
        }
        if (!values.sortId) {
            errors.sortId = 'Required';
        }
        if (!values.type || values.type.trim() === '') {
            errors.type = 'Required';
        }
        if (!values.closingDate) {
            errors.closingDate = 'Required';
        }
        if (!values.capacity) {
            errors.capacity = 'Required';
        }
        if (!values.reserve && values.reserve !== 0) {
            errors.reserve = 'Required';
        }
        if(!values.subject) {
            errors.subject = 'Required';
        }
        if(!values.htmlBody) {
            errors.htmlBody = 'Required';
        }

        // additional validations
        if (values.closingDate != null && !values.closingDate.isValid) {
            errors.closingDate = 'Invalid Date';
        }
        if (values.capacity != null && isNaN(Number(values.capacity))) {
            errors.capacity = 'Value must be a number';
        }
        if (values.capacity != null && !values.currency) {
            errors.currency = "Enter the capacity's currency";
        }
        if (values.reserve != null && isNaN(Number(values.reserve))) {
            errors.reserve = 'Value must be a number';
        }

        const now = DateTime.now();
        if(!values.isActive && now <= values.closingDate.endOf('day')) {
            errors.isActive = "Can't deactivate a fund when the closing date is today or in future";
        }

        return errors;
    }, []);

    let allVariables = `All variables: \${RMC_XXX}, \${TICKET}, \${STATUS}, \${CLOSING_DATE}, \${RMC_CLASSIFICATION}, \${SUB_TICKET}, \${CONFIRMED_AMOUNT}, \${WAITINGLIST_AMOUNT}`

    return (
        <Form<IOfferingFormValues>
            onSubmit={onSubmit}
            initialValues={{
                ...offering,
                closingDate: offering?.closingDate ? DateTime.fromISO(offering.closingDate) : undefined,
                currency: offering?.currency ?? 'USD',
                isActive: offering?.isActive ?? true,
            }}
            validate={onValidate}
            render={({ handleSubmit, pristine, submitting }) => (
                <Paper>
                    <form className="offering-form" onSubmit={handleSubmit}>
                        <Grid container={true} spacing={2}>
                            <Grid className="offering-form__settings" item={true} xs={4}>
                                <Grid container={true} spacing={2}>
                                    <Grid item={true} xs={12}>
                                        <Field name="fundName">
                                            {(props) => (
                                                <TextField
                                                    {...props.input}
                                                    label="Fund Name"
                                                    error={props.meta.error && props.meta.touched}
                                                    helperText={props.meta.error && props.meta.touched && props.meta.error}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12}>
                                        <Field name="fundShortName">
                                            {(props) => (
                                                <TextField
                                                    {...props.input}
                                                    label="Fund Short Name"
                                                    error={props.meta.error && props.meta.touched}
                                                    helperText={props.meta.error && props.meta.touched && props.meta.error}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12}>
                                        <Field name="sortId">
                                            {(props) => (
                                                <TextField
                                                    {...props.input}
                                                    label="Sort ID"
                                                    error={props.meta.error && props.meta.touched}
                                                    helperText={props.meta.error && props.meta.touched && props.meta.error}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12}>
                                        <Field name="type">
                                            {(props) => (
                                                <FormControl error={props.meta.error && props.meta.touched} >
                                                    <InputLabel id="offering-form-fund-type">Type of Fund</InputLabel>
                                                    <Select
                                                        {...props.input}
                                                        labelId="offering-form-fund-type"
                                                        label="Type of Fund"
                                                    >
                                                        <MenuItem value="">&nbsp;</MenuItem>
                                                        <MenuItem value={FundType.CO_INVESTMENT}>Coinvestment</MenuItem>
                                                        <MenuItem value={FundType.FEEDER}>Feeder</MenuItem>
                                                        <MenuItem value={FundType.VINTAGE}>FOF</MenuItem>
                                                    </Select>
                                                    <FormHelperText>{props.meta.error && props.meta.touched && props.meta.error}</FormHelperText>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12}>
                                        <Field name="closingDate">
                                            {(props) => (
                                                <DatePicker
                                                    {...props.input}
                                                    label="Closing Date (DD.MM.YYYY)"
                                                    inputFormat="dd.MM.yyyy"
                                                    onChange={(date: any) => {
                                                        props.input.onChange(date);
                                                    }}
                                                    renderInput={(params: any) => (
                                                        <TextField
                                                            {...params}
                                                            error={props.meta.error && props.meta.touched}
                                                            helperText={
                                                                props.meta.error && props.meta.touched && props.meta.error
                                                            }
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={8}>
                                        <Field name="capacity">
                                            {(props) => (
                                                <TextField
                                                    {...props.input}
                                                    label="Capacity"
                                                    error={props.meta.error && props.meta.touched}
                                                    helperText={props.meta.error && props.meta.touched && props.meta.error}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={4}>
                                        <Field name="currency">
                                            {(props) => (
                                                <FormControl>
                                                    <InputLabel id="offering-form-currency">Currency</InputLabel>
                                                    <Select
                                                        {...props.input}
                                                        labelId="offering-form-currency"
                                                        label="Currency"
                                                    >
                                                        <MenuItem value="USD">USD</MenuItem>
                                                        <MenuItem value="EUR">EUR</MenuItem>
                                                        <MenuItem value="CHF">CHF</MenuItem>
                                                        <MenuItem value="GBP">GBP</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12}>
                                        <Field name="reserve">
                                            {(props) => (
                                                <TextField
                                                    {...props.input}
                                                    label="Reserve in %"
                                                    error={props.meta.error && props.meta.touched}
                                                    helperText={props.meta.error && props.meta.touched && props.meta.error}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={6}>
                                        <Field name="isActive" type="checkbox">
                                            {(props) => (
                                                <FormControl
                                                    error={props.meta.error && props.meta.touched}
                                                    className={(props.meta.error && props.meta.touched) ? 'error' : ''}
                                                >
                                                    <FormControlLabel
                                                        control={<Checkbox {...props.input} />}
                                                        label="Active"
                                                    />
                                                    {(props.meta.error && props.meta.touched) && <small className="error">{props.meta.error}</small>}
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12}>
                                        <Button type="submit" disabled={pristine || submitting}>
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className="offering-form__mail-template" item={true} xs={8}>
                                <Grid container={true} spacing={2}>
                                    <Grid item={true} xs={12}>
                                        <p><small>{allVariables}</small></p>
                                        <Field name="subject">
                                            {(props) => (
                                                <TextField
                                                    {...props.input}
                                                    label="Subject"
                                                    error={props.meta.error && props.meta.touched}
                                                    helperText={props.meta.error && props.meta.touched && props.meta.error}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12}>
                                        <Field name="htmlBody">
                                            {(props) => (
                                                <TextField
                                                    {...props.input}
                                                    multiline={true}
                                                    minRows={5}
                                                    label="Mail Template (HTML Body)"
                                                    error={props.meta.error && props.meta.touched}
                                                    helperText={props.meta.error && props.meta.touched && props.meta.error}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            )}
        />
    );
});
