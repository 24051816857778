import * as React from 'react';
import { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { ArrowForward } from '@mui/icons-material';
import { Button, FormControl, FormControlLabel, Switch, TextField } from '@mui/material';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { ValidationErrors } from 'final-form';
import { ISettings, useResetIsReading, useSettings, useUpdateSettings } from '../../api';
import { RequestState } from '../../api/types';
import { PageHeader } from '../../components/page-header';
import { LoadingSpinner } from '../../components/loading-spinner';

export const SettingsPage: React.FC = () => {
    const { data: settings, requestState, responseStatusCode } = useSettings();
    const { update, requestState: updateRequestState, responseStatusCode: updateResponseStatusCode } = useUpdateSettings();

    const { create: resetIsReading, requestState: resetRequestState, responseStatusCode: resetResponseStatusCode } = useResetIsReading();

    const onResetIsReading = useCallback(() => {
        resetIsReading({});
    }, [resetIsReading]);

    const onValidate = useCallback((values: any): ValidationErrors => {
        const errors: ValidationErrors = {};

        if (values.mailRedirectEnabled) {
            const addresses = values.mailRedirectAddress?.split(";") as undefined | string[] ?? [];
            if (!values.mailRedirectAddress?.trim()) {
                errors.mailRedirectAddress = 'Redirect Address is required when activated';
            } else if (addresses.some(address => !address.endsWith("@juliusbaer.com"))) {
                errors.mailRedirectAddress = 'Not all mail addresses end with @juliusbaer.com';
            }
        }

        const subdocsSettingsErrors: ValidationErrors[] = [];

        values.subdocsSettings?.forEach((subdocsSetting: any, index: number) => {
            const subdocsErrors: ValidationErrors = {};

            // required validation checks
            if (!subdocsSetting.bookingCenters) {
                subdocsErrors.bookingCenters = 'Required';
            }
            if (!subdocsSetting.regionPath) {
                subdocsErrors.regionPath = 'Required';
            }
            /*if (subdocsSetting.mailSendingEnabled == null) {
                subdocsErrors.mailSendingEnabled = 'Required';
            }
            if (subdocsSetting.mailReadingEnabled == null) {
                subdocsErrors.mailReadingEnabled = 'Required';
            }*/

            subdocsSettingsErrors.push(subdocsErrors)

            // additional validation
            const commaSeparatedBusinessCenters = /(^([a-zA-Z]{2}(,([a-zA-Z]{2}))*$))/;
            // first index is 'Rest'
            if (index > 0 && subdocsSetting.bookingCenters && !subdocsSetting.bookingCenters?.match(commaSeparatedBusinessCenters)) {
                subdocsErrors.bookingCenters = 'Booking Centers must be a comma separated value with two letters each. e.g.: SG,HK';
            }

            const forbiddenPathCharacters = ['<', '>', ':', '"', '|', '?', '*'];
            if (forbiddenPathCharacters.some(char => subdocsSetting.regionPath?.includes(char))) {
                subdocsErrors.regionPath = 'Path must not contain any of the following characters: >, <, :, ", |, ?, *';
            }
        });

        errors.subdocsSettings = subdocsSettingsErrors;

        return errors;
    }, []);

    const handleOnSubmit = useCallback((values: any) => {
        const updatedSettings: ISettings = {
            mailSendingEnabled: values.mailSendingEnabled,
            mailReadingEnabled: values.mailReadingEnabled,
            mailRedirectEnabled: values.mailRedirectEnabled,
            mailRedirectAddress: values.mailRedirectAddress,
            subdocsSettings: values.subdocsSettings,
        }

        update(updatedSettings);
    }, [update]);

    return <div className="settings">
        <PageHeader title="Settings" />
        <Form
            mutators={{
                ...arrayMutators
            }}
            onSubmit={handleOnSubmit}
            initialValues={settings}
            validate={onValidate}
            render={({ handleSubmit, pristine, submitting, form }) => (
                <form className="settings-form" onSubmit={handleSubmit}>
                    <h2>SubDocs Team Region Paths</h2>
                    { (requestState === RequestState.IDLE || requestState === RequestState.LOADING) && <LoadingSpinner /> }
                    { requestState === RequestState.ERROR && <>
                        { responseStatusCode === 401 && <div className="error">Unauthorized</div> }
                        { responseStatusCode !== 401 && <div className="error">An unexpected error occurred while loading the settings</div> }
                    </> }
                    { requestState === RequestState.SUCCESS && (
                        <div className="settings__subdocs">
                            <FieldArray
                                name="subdocsSettings"
                                render={({ fields }) => {
                                    return fields.map((name, index) => {
                                        return <div key={`${name}_${index}`}>
                                            <div className="settings__subdocs__row">
                                                <div className="settings__subdocs__row__business-centers">
                                                    <Field name={`${name}.bookingCenters`} className="settings__subdocs__field">
                                                        {(props) => (
                                                            <TextField
                                                                {...props.input}
                                                                label="Booking Centers"
                                                                error={props.meta.error && props.meta.touched}
                                                                helperText={props.meta.error && props.meta.touched && props.meta.error}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>

                                                <div className="settings__subdocs__arrow"><ArrowForward /></div>

                                                <div className="settings__subdocs__row__region-path">
                                                    <span>{settings?.exportPathPrefix}</span>
                                                    <div className="settings__subdocs__row__region-path__input">
                                                        <Field name={`${name}.regionPath`} className="settings__subdocs__field">
                                                            {(props) => (
                                                                <TextField
                                                                    {...props.input}
                                                                    label="Region Path"
                                                                    error={props.meta.error && props.meta.touched}
                                                                    helperText={props.meta.error && props.meta.touched && props.meta.error}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>

                                                {index > 0 && // first index is 'Rest'
                                                    <Button type="button" onClick={() => fields.remove(index)}>
                                                        Remove
                                                    </Button>
                                                }
                                            </div>
                                            <div><small>Results in: <i>{settings?.exportPathPrefix}<b>{fields.value[index].regionPath}</b>{settings?.exportPathPostfix}</i></small></div>
                                        </div>
                                    });
                                }}
                            />

                            <div className="settings__subdocs__add">
                                <Button type="button" onClick={() => form.mutators.push('subdocsSettings', { bookingCenters: '', regionPath: 'PrivateMarketsRegion' })}>
                                Add
                                </Button>
                            </div>

                            <div className="error settings__subdocs__hint"><small><b>ATTENTION:</b> When adding new regions / sub paths, make sure the service user used for exporting has rights to write in that directory!</small></div>
                        </div>
                    )}

                    {/*<h2>Mail Recipients</h2>*/}
                    {/*<small>TODO:</small>*/}

                    {/*<h2>Booking Centers</h2>*/}
                    {/*<small>TODO:</small>*/}

                    <h2>Application Settings</h2>
                    <div>
                        <Field name="mailReadingEnabled" type="checkbox">
                            {(props) => (
                                <FormControl>
                                    <FormControlLabel
                                        control={<Switch {...props.input} />}
                                        label={"Application reads mails (" + (props.input.checked ? "On" : "Off") + ")"}
                                    />
                                </FormControl>
                            )}
                        </Field>
                    </div>
                    <div>
                        <Field name="mailSendingEnabled" type="checkbox">
                            {(props) => (
                                <FormControl>
                                    <FormControlLabel
                                        control={<Switch {...props.input} />}
                                        label={"Application sends mails (" + (props.input.checked ? "On" : "Off") + ")"}
                                    />
                                </FormControl>
                            )}
                        </Field>
                    </div>
                    <div>
                        <Field name="mailRedirectEnabled" type="checkbox">
                            {(props) => (
                                <FormControl>
                                    <FormControlLabel
                                        control={<Switch {...props.input} />}
                                        label={"Application redirects mails to the specified addresses (if \"Application sends mails\" is On)."}
                                    />
                                </FormControl>
                            )}
                        </Field>

                        <br />
                        <br />

                        <Field name="mailRedirectAddress">
                            {(props) => (
                                <TextField
                                    {...props.input}
                                    label="Redirect mail addresses"
                                    error={props.meta.error && props.meta.touched}
                                    helperText={props.meta.error && props.meta.touched && props.meta.error}
                                />
                            )}
                        </Field>

                    </div>

                    <h2>Save</h2>
                    <div className="settings__save">
                        <Button type="submit" disabled={pristine || submitting}>
                            Save all Settings
                        </Button>
                    </div>
                    { updateRequestState === RequestState.LOADING && <LoadingSpinner /> }
                    { updateRequestState === RequestState.ERROR && <>
                        { updateResponseStatusCode === 401 && <div className="error">Unauthorized</div> }
                        { updateResponseStatusCode !== 401 && <div className="error">An unexpected error occurred while saving the settings</div> }
                    </> }
                    { updateRequestState === RequestState.SUCCESS && <div>Saved.</div> }
                </form>
            )}
        />

        <h2>Reset mail reading state</h2>
        <p><small>This button resets the mail reading state. It should only be used if the application is stuck (check with ITSO first).</small></p>
        <Button type="button" onClick={onResetIsReading} disabled={resetRequestState === RequestState.LOADING}>Reset mail reading state</Button>
        { resetRequestState === RequestState.LOADING && <div><LoadingSpinner /></div> }
        { resetRequestState === RequestState.ERROR && <>
            { resetResponseStatusCode === 401 && <div className="error">Unauthorized</div> }
            { resetResponseStatusCode !== 401 && <div className="error">An unexpected error occurred while loading the settings</div> }
        </> }
        { resetRequestState === RequestState.SUCCESS && <p>Reset done.</p> }
    </div>;
}