import axios, { AxiosResponse } from 'axios';
import { generatePath } from 'react-router-dom';
import { IRequestOptions } from '../types';
import { ICountryList } from '../domain';

enum Url {
    COUNTRY_LIST = '/country-list'
}

export const fetchCountryList: (options?: IRequestOptions<ICountryList>) => Promise<AxiosResponse<ICountryList>> = (options) => {
    return axios.get<ICountryList>(generatePath(Url.COUNTRY_LIST));
};

export const updateCountryList: (list: ICountryList, options?: IRequestOptions) => Promise<AxiosResponse<ICountryList>> = (
    list
) => {
    return axios.put<ICountryList>(Url.COUNTRY_LIST, list);
};