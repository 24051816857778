import { useMemo } from 'react';
import { IOffering } from '../../domain';
import { IGetRequestResponse } from '../../types';
import { fetchOffering } from '../../api-calls';
import { useAxiosGetRequest } from '../use-axios-request';

interface IOfferingResponse extends IGetRequestResponse<IOffering> {}

export const useOffering = (id: string): IOfferingResponse => {
    const options = useMemo(() => {
        return { data: { id } };
    }, [id]);

    return useAxiosGetRequest<IOffering>(fetchOffering, options);
};
