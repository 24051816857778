import axios, { AxiosResponse } from 'axios';
import { generatePath } from 'react-router-dom';
import { IRequestOptions } from '../types';

enum Url {
    UPLOAD = '/upload',
    UPLOAD_ADDITIONAL_STAFF_LIST = '/upload/additional-staff-list',
    UPLOAD_BJB_STAFF_LIST = '/upload/staff-list',
    UPLOAD_CHECKLIST_OVERRIDE = '/upload/checklist-override',
    UPLOAD_REVIEWERS = '/upload/reviewers',
}

export const uploadFiles: (formData: Partial<FormData>, options?: IRequestOptions)
    => Promise<AxiosResponse<void>> = (formData) => {
    return axios.post(generatePath(Url.UPLOAD), formData as any); // todo: as any
};

export const uploadAdditionalStaffList: (formData: Partial<FormData>, options?: IRequestOptions)
    => Promise<AxiosResponse<void>> = (formData) => {
    return axios.post(generatePath(Url.UPLOAD_ADDITIONAL_STAFF_LIST), formData as any); // todo: as any
};

export const uploadBJBStaffList: (formData: Partial<FormData>, options?: IRequestOptions)
    => Promise<AxiosResponse<void>> = (formData) => {
    return axios.post(generatePath(Url.UPLOAD_BJB_STAFF_LIST), formData as any); // todo: as any
};

export const uploadChecklistOverride: (formData: Partial<FormData>, options?: IRequestOptions)
    => Promise<AxiosResponse<void>> = (formData) => {
    return axios.post(generatePath(Url.UPLOAD_CHECKLIST_OVERRIDE), formData as any); // todo: as any
};

export const uploadReviewers: (formData: Partial<FormData>, options?: IRequestOptions)
    => Promise<AxiosResponse<void>> = (formData) => {
    return axios.post(generatePath(Url.UPLOAD_REVIEWERS), formData as any); // todo: as any
};