import { IOffering } from '../../domain';
import { updateOffering } from '../../api-calls';
import { IPutRequestResponse } from '../../types';
import { useAxiosPutRequest } from '../use-axios-request';

interface IUpdateOfferingResponse extends IPutRequestResponse<IOffering> {}

export const useUpdateOffering = (): IUpdateOfferingResponse => {
    return useAxiosPutRequest<IOffering>(updateOffering);
};
