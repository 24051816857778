export const isDevEnv = () => {
    return window.location.host === 'localhost:3000';
}

export const isTestEnv = () => {
    return window.location.host === 'pepo-sub.master.pepo-sub.ha1.sehlat.io';
}

export const isUatEnv = () => {
    return window.location.host === 'pes-es-pepo-sub-uat.juliusbaer.com';
}

export const isDevOrTestEnv = () => {
    return isDevEnv() || isTestEnv();
}

export const isDevOrTestOrUatEnv = () => {
    return isDevEnv() || isTestEnv() || isUatEnv();
}
