import { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import {
    IGetRequestResponse,
    IPostRequestResponse,
    IPutRequestResponse,
    IRequestOptions,
    RequestState,
} from '../types';

export function useAxiosGetRequest<TData>(
    axiosRequest: (options?: IRequestOptions) => Promise<AxiosResponse<TData>>,
    options?: IRequestOptions
): IGetRequestResponse<TData> {
    /**
     * helper hook to access data via axios
     *
     * @param axiosRequest axios request fn that returns data of the type TData
     * @param options parameter for axiosRequest - axiosRequest(options). must be constant or it could generate infinite requests.
     */
    const [requestState, setRequestState] = useState<RequestState>(RequestState.IDLE);
    const [responseStatusCode, setResponseStatusCode] = useState<number>(0);
    const [data, setData] = useState<TData>();

    useEffect(() => {
        axiosRequest(options)
            .then((response) => {
                setData(response.data);
                setRequestState(RequestState.SUCCESS);
                setResponseStatusCode(response.status);
            })
            .catch((error) => {
                setRequestState(RequestState.ERROR);
                setResponseStatusCode(error.response.status);
            });
        setRequestState(RequestState.LOADING);
    }, [axiosRequest, options, setRequestState, setResponseStatusCode, setData]);

    return { requestState, responseStatusCode, data };
}

/**
 * helper hook to create data via axios
 *
 * @param axiosRequest axios request fn that posts data of the type TData
 * @param options parameter for axiosRequest - axiosRequest(options). must be constant or it could generate infinite requests.
 */
export function useAxiosPostRequest<TData, TDataResponse = TData>(
    axiosRequest: (data: Partial<TData>, options?: IRequestOptions) => Promise<AxiosResponse<TDataResponse>>,
    options?: IRequestOptions
): IPostRequestResponse<TData, TDataResponse> {
    const [requestState, setRequestState] = useState<RequestState>(RequestState.IDLE);
    const [responseStatusCode, setResponseStatusCode] = useState<number>(0);
    const [data, setData] = useState<TDataResponse>();

    const create = useCallback(
        (data: Partial<TData>) => {
            axiosRequest(data, options)
                .then((response) => {
                    setData(response.data);
                    setRequestState(RequestState.SUCCESS);
                    setResponseStatusCode(response.status);
                })
                .catch((error) => {
                    setRequestState(RequestState.ERROR);
                    setResponseStatusCode(error.response.status);
                });
            setRequestState(RequestState.LOADING);
        },
        [axiosRequest, options, setRequestState, setResponseStatusCode, setData]
    );

    return { create, requestState, responseStatusCode, data };
}

/**
 * helper hook to update data via axios
 *
 * @param axiosRequest axios request fn that returns puts of the type TData
 * @param options parameter for axiosRequest - axiosRequest(options). must be constant or it could generate infinite requests.
 */
export function useAxiosPutRequest<TData>(
    axiosRequest: (data: TData, options?: IRequestOptions) => Promise<AxiosResponse<TData>>,
    options?: IRequestOptions
): IPutRequestResponse<TData> {
    const [requestState, setRequestState] = useState<RequestState>(RequestState.IDLE);
    const [responseStatusCode, setResponseStatusCode] = useState<number>(0);
    const [data, setData] = useState<TData>();

    const update = useCallback(
        (data: TData) => {
            axiosRequest(data, options)
                .then((response) => {
                    setData(response.data);
                    setRequestState(RequestState.SUCCESS);
                    setResponseStatusCode(response.status);
                })
                .catch((error) => {
                    setRequestState(RequestState.ERROR);
                    setResponseStatusCode(error.response.status);
                });
            setRequestState(RequestState.LOADING);
        },
        [axiosRequest, options, setRequestState, setResponseStatusCode, setData]
    );

    return { update, requestState, responseStatusCode, data };
}
