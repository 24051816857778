import React, { useCallback } from 'react';
import { Button, TextField } from '@mui/material';
import { PageHeader } from '../../components/page-header';
import { FileUpload } from '../../components/file-upload';
import {
    useChecklistOverrideUpload,
    useFileUpload,
    useGenerateTestData,
    useReadMails,
    useResetDev,
    useReviewersUpload
} from '../../api';
import { RequestState } from '../../api/types';
import { LoadingSpinner } from '../../components/loading-spinner';
import { Field, Form } from 'react-final-form';

interface IFormValues {
    amount: number;
}

export const DevPage: React.FC = () => {
    const { create: reset, requestState: resetRequestState, responseStatusCode: resetResponseStatusCode } = useResetDev();
    const { create: generateTestData, requestState: generateTestDataRequestState } = useGenerateTestData();
    const { create: readMails, requestState: readMailsRequestState, responseStatusCode: readMailResponseStatusCode } = useReadMails();

    const { requestState, create: fileUpload, responseStatusCode: uploadResponseStatusCode } = useFileUpload();
    const { requestState: checklistOverrideRequestState, create: checklistOverrideUpload, responseStatusCode: checklistOverrideUploadResponseStatusCode } = useChecklistOverrideUpload();
    const { requestState: reviewersUploadRequestState, create: reviewersUpload, responseStatusCode: reviewersUploadResponseStatusCode } = useReviewersUpload();

    const onUpload = useCallback((files: File[]) => {
        // https://docs.microsoft.com/en-us/aspnet/core/mvc/models/file-uploads?view=aspnetcore-6.0#match-name-attribute-value-to-parameter-name-of-post-method
        const formData = new FormData();

        files.forEach((file) => {
            formData.append('files', file, file.name);
        });

        fileUpload(formData);
    }, [fileUpload]);

    const onChecklistOverrideUpload = useCallback((files: File[]) => {
        // https://docs.microsoft.com/en-us/aspnet/core/mvc/models/file-uploads?view=aspnetcore-6.0#match-name-attribute-value-to-parameter-name-of-post-method
        const formData = new FormData();

        files.forEach((file) => {
            formData.append('files', file, file.name);
        });

        checklistOverrideUpload(formData);
    }, [checklistOverrideUpload]);

    const onReviewersUpload = useCallback((files: File[]) => {
        // https://docs.microsoft.com/en-us/aspnet/core/mvc/models/file-uploads?view=aspnetcore-6.0#match-name-attribute-value-to-parameter-name-of-post-method
        const formData = new FormData();

        files.forEach((file) => {
            formData.append('files', file, file.name);
        });

        reviewersUpload(formData);
    }, [reviewersUpload]);

    const onSubmit = useCallback(
        (values: IFormValues) => {
            generateTestData({ amount: values.amount });
        },
        [generateTestData]
    );

    return (
        <div className="dev">
            <PageHeader title="Dev Page" />
            <small><i>Page used for dev purposes</i></small>

            <h2>File Upload</h2>
            {/* todo: these values should be defined in BE; 10485760 bytes = 10 MB*/}
            <FileUpload accept=".msg" disabled={requestState === RequestState.LOADING || resetRequestState === RequestState.LOADING || generateTestDataRequestState === RequestState.LOADING} maxSize={10485760} onUpload={onUpload} />
            <div>
                {requestState === RequestState.LOADING && (
                    <>
                        <span>Uploading files...</span>
                        <LoadingSpinner />
                    </>
                )}
                {requestState === RequestState.ERROR && (
                    <>
                        { uploadResponseStatusCode === 401 && <p className="error">Unauthorized</p> }
                        { uploadResponseStatusCode !== 401 && <p className="error">An unexpected error occurred while uploading files.</p> }
                    </>
                )}
                {requestState === RequestState.SUCCESS && <p>Uploaded files successfully</p>}
            </div>

            <h2>Checklist Override Upload</h2>
            {/* todo: these values should be defined in BE; 10485760 bytes = 10 MB*/}
            <FileUpload
                accept={[".xls", ".xlsx"]}
                disabled={checklistOverrideRequestState === RequestState.LOADING || resetRequestState === RequestState.LOADING || generateTestDataRequestState === RequestState.LOADING}
                maxSize={10485760}
                singleFile={true}
                onUpload={onChecklistOverrideUpload}
            />
            <div>
                {checklistOverrideRequestState === RequestState.LOADING && (
                    <>
                        <span>Uploading...</span>
                        <LoadingSpinner />
                    </>
                )}
                {checklistOverrideRequestState === RequestState.ERROR && (
                    <>
                        { checklistOverrideUploadResponseStatusCode === 401 && <p className="error">Unauthorized</p> }
                        { checklistOverrideUploadResponseStatusCode !== 401 && <p className="error">An unexpected error occurred while uploading.</p> }
                    </>
                )}
                {checklistOverrideRequestState === RequestState.SUCCESS && <p>Uploaded successfully</p>}
            </div>

            <h2>Reviewers Upload</h2>
            {/* todo: these values should be defined in BE; 10485760 bytes = 10 MB*/}
            <FileUpload
                accept={[".xls", ".xlsx"]}
                disabled={reviewersUploadRequestState === RequestState.LOADING || resetRequestState === RequestState.LOADING || generateTestDataRequestState === RequestState.LOADING}
                maxSize={10485760}
                singleFile={true}
                onUpload={onReviewersUpload}
            />
            <div>
                {reviewersUploadRequestState === RequestState.LOADING && (
                    <>
                        <span>Uploading...</span>
                        <LoadingSpinner />
                    </>
                )}
                {reviewersUploadRequestState === RequestState.ERROR && (
                    <>
                        { reviewersUploadResponseStatusCode === 401 && <p className="error">Unauthorized</p> }
                        { reviewersUploadResponseStatusCode !== 401 && <p className="error">An unexpected error occurred while uploading.</p> }
                    </>
                )}
                {reviewersUploadRequestState === RequestState.SUCCESS && <p>Uploaded successfully</p>}
            </div>

            <h2>Read Mails via EWS</h2>
            <Button onClick={readMails} disabled={readMailsRequestState === RequestState.LOADING || resetRequestState === RequestState.LOADING || generateTestDataRequestState === RequestState.LOADING}>Read Mails</Button>
            <div>
                {readMailsRequestState === RequestState.LOADING && <LoadingSpinner />}
                {readMailsRequestState === RequestState.SUCCESS && <span>Done</span>}
                {readMailsRequestState === RequestState.ERROR && <>
                    { readMailResponseStatusCode === 401 && <span className="error">Unauthorized</span> }
                    { readMailResponseStatusCode !== 401 && <span className="error">An unexpected error occurred.</span> }
                </>}
            </div>

            <h2>Reset DB</h2>
            <Button
                onClick={reset}
                disabled={resetRequestState === RequestState.LOADING || requestState === RequestState.LOADING || readMailsRequestState === RequestState.LOADING || generateTestDataRequestState === RequestState.LOADING}
            >Reset DB</Button>
            <div><small><i>Clears all tables <b>apart of Offerings</b> and resets the reference sequences</i></small></div>
            <div>
                {resetRequestState === RequestState.LOADING && <LoadingSpinner />}
                {resetRequestState === RequestState.SUCCESS && <span>Done</span>}
                {resetRequestState === RequestState.ERROR && <>
                    { resetResponseStatusCode === 401 && <span className="error">Unauthorized.</span> }
                    { resetResponseStatusCode !== 401 && <span className="error">An unexpected error occurred.</span> }
                </>}
            </div>

            <h2>Generate Test Data</h2>

            <Form<IFormValues>
                onSubmit={onSubmit}
                render={({ handleSubmit, pristine, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <Field name="amount">
                            {(props) => (
                                <TextField
                                    {...props.input}
                                    label="Amount"
                                    error={props.meta.error && props.meta.touched}
                                    helperText={props.meta.error && props.meta.touched && props.meta.error}
                                />
                            )}
                        </Field>
                        <Button type="submit" disabled={pristine || submitting || resetRequestState === RequestState.LOADING || requestState === RequestState.LOADING || readMailsRequestState === RequestState.LOADING || generateTestDataRequestState === RequestState.LOADING}>
                            Generate Test Data
                        </Button>
                    </form>
                )}
            />
        </div>
    );
};
