import { useMemo } from 'react';
import { IMailTemplate } from '../../domain';
import { IGetRequestResponse } from '../../types';
import { fetchMailTemplate } from '../../api-calls';
import { useAxiosGetRequest } from '../use-axios-request';

interface IMailTemplateResponse extends IGetRequestResponse<IMailTemplate> {}

export const useMailTemplate = (id: string): IMailTemplateResponse => {
    const options = useMemo(() => {
        return { data: { id } };
    }, [id]);

    return useAxiosGetRequest<IMailTemplate>(fetchMailTemplate,options);
};