import React, { useCallback } from 'react';
import { IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface IPageHeaderProps {
    title: string;
    hasBackNavigation?: boolean;
}

export const PageHeader: React.FC<IPageHeaderProps> = ({ title, hasBackNavigation }) => {
    const navigate = useNavigate();

    const onNavigateBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    return (
        <div className="page-header">
            {hasBackNavigation && (
                <div className="page-header__back-navigation">
                    <IconButton onClick={onNavigateBack}>
                        <ArrowBack />
                    </IconButton>
                </div>
            )}
            <h1>{title}</h1>
        </div>
    );
};
