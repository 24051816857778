import { ICountryList } from '../../domain';
import { IGetRequestResponse } from '../../types';
import { fetchCountryList } from '../../api-calls';
import { useAxiosGetRequest } from '../use-axios-request';

interface ICountryListResponse extends IGetRequestResponse<ICountryList> {}

export const useCountryList = (): ICountryListResponse => {
    return useAxiosGetRequest<ICountryList>(fetchCountryList);
};
