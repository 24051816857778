import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { Paths } from '../../routing/paths';
import { OfferingsTable } from '../../components/offerings-table';
import { PageHeader } from '../../components/page-header';

export const OfferingOverview: React.FC = () => {
    const navigate = useNavigate();

    const navigateToNewOffering = useCallback(() => {
        navigate(Paths.OFFERING_NEW);
    }, [navigate]);

    return (
        <div className="offering-overview">
            <PageHeader title="Offering Overview" />
            <div className="offering-overview__new-offering">
                <Button onClick={navigateToNewOffering}>New Offering</Button>
            </div>
            <OfferingsTable />
        </div>
    );
};
