import * as React from 'react';
import { useHasAccess } from '../../api';
import { RequestState } from '../../api/types';
import { LoadingSpinner } from '../loading-spinner';

interface IAccessWrapperProps {
    children?: React.ReactNode;
    hideText?: boolean;
}

export const AccessWrapper: React.FC<IAccessWrapperProps> = ({ children, hideText }) => {
    const { requestState: hasAccessRequestState, responseStatusCode } = useHasAccess();

    if (hasAccessRequestState !== RequestState.SUCCESS) {
        if (hideText) {
            return <></>;
        }

        return (
            <div className="content">
                { (hasAccessRequestState === RequestState.IDLE || hasAccessRequestState === RequestState.LOADING) && <LoadingSpinner /> }
                { hasAccessRequestState === RequestState.ERROR && responseStatusCode === 403 && <span className="error">403 Forbidden: You don't have access to this page.</span> }
                { hasAccessRequestState === RequestState.ERROR && responseStatusCode === 401 && <span className="error">Unauthorized - Starting a new session could help (e.g. new tab)</span> }
                { hasAccessRequestState === RequestState.ERROR && responseStatusCode !== 401 && responseStatusCode !== 403 && <span className="error">An unexpected error occurred while loading data</span> }
            </div>
        );
    }

    return <>{children}</>;
}