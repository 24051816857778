import { IGetRequestResponse } from '../../types';
import { fetchReviewSettingsConfiguration } from '../../api-calls';
import { useAxiosGetRequest } from '../use-axios-request';
import { IReviewSettingsConfiguration } from '../../domain/review-settings-configuration';

interface IReviewSettingsConfigurationResponse extends IGetRequestResponse<IReviewSettingsConfiguration> {}

export const useReviewSettingsConfiguration = (): IReviewSettingsConfigurationResponse => {
    return useAxiosGetRequest<IReviewSettingsConfiguration>(fetchReviewSettingsConfiguration);
};
