import React, { useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { Button } from '@mui/material';
import { Paths } from '../../routing/paths';
import { Home } from '../../pages/home';
import { BlacklistPage } from '../../pages/blacklist-page';
import { CountryList } from '../../pages/country-list-page';
import { MailTemplateOverview } from '../../pages/mail-template-overview';
import { MailTemplateEdit } from '../../pages/mail-template-edit';
import { OfferingOverview } from '../../pages/offering-overview';
import { OfferingEdit } from '../../pages/offering-edit';
import { OfferingCreate } from '../../pages/offering-create';
import { ReviewSettings } from '../../pages/review-settings';
import { SettingsPage } from '../../pages/settings-page';
import { StaffList } from '../../pages/staff-list';
import { DevPage } from '../../pages/dev-page';
import { isDevOrTestOrUatEnv } from '../../utils/env-utils';
import { Navigation } from '../navigation';
import { LoginCallback } from '../login-callback';
import { LoginRedirectToIdp } from '../login-redirect-to-idp';
import { AccessWrapper } from '../access-wrapper';

interface IApplicationProps {
    version: string;
}

export const Application: React.FC<IApplicationProps> = ({ version }) => {
    // get authorization context
    const auth = useAuth();

    useEffect(() => {
        auth.startSilentRenew();
    });

    useEffect(() => {
        // the `return` is important - addAccessTokenExpiring() returns a cleanup function
        return auth.events.addAccessTokenExpiring(() => {
            auth.signinSilent();
        })
    }, [auth]);

    if (auth.activeNavigator === 'signinSilent') {
        return <div className="content">Signing in...</div>;
    } else if (auth.activeNavigator === 'signinRedirect') {
        return <div className="content">Redirecting...</div>;
    } else if (auth.activeNavigator === 'signoutRedirect') {
        return <div className="content">Signing out...</div>;
    }

    if (auth.isLoading) {
        return <div className="content">Loading...</div>;
    }

    if (auth.error) {
        return (
            <div className="content">
                <span className="error">An unexpected error occurred during authentication: {auth.error?.message}</span>
                <br />
                <br />
                <Button onClick={() => auth.signinRedirect()}>Retry</Button>
            </div>
        );
    }

    // only load the application when the user is authenticated
    if (auth.isAuthenticated) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + auth.user?.access_token;

        return (
            <React.StrictMode>
                <div className="application">
                    <BrowserRouter>
                        <Navigation version={version} />
                        <div className="content">
                            <Routes>
                                <Route path={Paths.LOGIN_CALLBACK} element={<LoginCallback />} />
                                <Route path="/" element={<AccessWrapper><Home /></AccessWrapper>} />
                                <Route path={Paths.MAIL_TEMPLATES} element={<AccessWrapper><MailTemplateOverview /></AccessWrapper>} />
                                <Route path={Paths.MAIL_TEMPLATE_EDIT} element={<AccessWrapper><MailTemplateEdit /></AccessWrapper>} />
                                <Route path={Paths.OFFERINGS} element={<AccessWrapper><OfferingOverview /></AccessWrapper>} />
                                <Route path={Paths.COUNTRY_LIST} element={<AccessWrapper><CountryList /></AccessWrapper>} />
                                <Route path={Paths.OFFERING_EDIT} element={<AccessWrapper><OfferingEdit /></AccessWrapper>} />
                                <Route path={Paths.OFFERING_NEW} element={<AccessWrapper><OfferingCreate /></AccessWrapper>} />
                                <Route path={Paths.REVIEW_SETTINGS} element={<AccessWrapper><ReviewSettings /></AccessWrapper>} />
                                <Route path={Paths.SETTINGS} element={<AccessWrapper><SettingsPage /></AccessWrapper>} />
                                <Route path={Paths.BLACKLIST} element={<AccessWrapper><BlacklistPage /></AccessWrapper>} />
                                <Route path={Paths.STAFF_LIST} element={<AccessWrapper><StaffList /></AccessWrapper>} />
                                { isDevOrTestOrUatEnv() &&
                                    <Route path={Paths.DEV} element={<AccessWrapper><DevPage /></AccessWrapper>}/>
                                }
                            </Routes>
                        </div>
                    </BrowserRouter>
                </div>
            </React.StrictMode>
        );
    }

    // redirect the user to the login page when none of the above conditions applies

    return <LoginRedirectToIdp signinRedirect={auth.signinRedirect} />;
};
