import { generateTestData } from '../../api-calls';
import { IPostRequestResponse } from '../../types';
import { useAxiosPostRequest } from '../use-axios-request';
import { IGenerateTestDataAmount } from '../../domain';

interface IDevResponse extends IPostRequestResponse<IGenerateTestDataAmount> {}

export const useGenerateTestData = (): IDevResponse => {
    return useAxiosPostRequest<IGenerateTestDataAmount>(generateTestData);
};
