import { ICountryList } from '../../domain';
import { IPutRequestResponse } from '../../types';
import { updateCountryList } from '../../api-calls';
import { useAxiosPutRequest } from '../use-axios-request';

interface IUpdateCountryListResponse extends IPutRequestResponse<ICountryList> {}

export const useUpdateCountryList = (): IUpdateCountryListResponse => {
    return useAxiosPutRequest<ICountryList>(updateCountryList);
};
