export enum RequestState {
    ERROR = 'ERROR',
    IDLE = 'IDLE', // this state is currently used when there hasn't been a network call yet
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
}

export interface IRequestOptions<TData = any> {
    data?: Partial<TData>;
    queryParams?: string;
}

interface IRequestResponse<TData> {
    data: TData | undefined;
    requestState: RequestState;
    responseStatusCode: number;
}

export interface IGetRequestResponse<TData> extends IRequestResponse<TData> {}
export interface IPostRequestResponse<TData, TDataResponse = TData> extends IRequestResponse<TDataResponse> {
    create: (data: Partial<TData>) => void;
}
export interface IPutRequestResponse<TData> extends IRequestResponse<TData> {
    update: (data: TData) => void;
}
