import { IStaffListUpdate } from '../../domain';
import { IGetRequestResponse } from '../../types';
import { fetchStaffListUpdates } from '../../api-calls';
import { useAxiosGetRequest } from '../use-axios-request';

interface IStaffListUpdateResponse extends IGetRequestResponse<IStaffListUpdate> {}

export const useStaffListUpdates = (): IStaffListUpdateResponse => {
    return useAxiosGetRequest<IStaffListUpdate>(fetchStaffListUpdates);
};
