import React from 'react';
import { createRoot } from 'react-dom/client';
import axios from 'axios';
import { AuthProvider } from 'react-oidc-context';
import { Log } from 'oidc-client-ts';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { ThemeProvider } from '@mui/material';
import 'normalize.css';
import './app/styles/index.scss';
import { Application } from './app/components/application';
import { theme } from './app/styles/mui-theme';
import { AppConfig } from './app/components/app-config';

if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = 'http://localhost:3000/api';
} else {
    axios.defaults.baseURL = '/api';
}

Log.setLogger(console);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <AppConfig>
        {(authProviderProps, version) =>
            // wrapping the application in AuthProvider that takes care of oidc and provides the AuthContext to the application.
            <AuthProvider {...authProviderProps}>
                {/* wrapping the application in LocalizationProvider to set the date adapter for the mui date picker components */}
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <ThemeProvider theme={theme}>
                        <Application version={version} />
                    </ThemeProvider>
                </LocalizationProvider>
            </AuthProvider>
        }
    </AppConfig>
);
