import React, { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { PageHeader } from '../../components/page-header';

import { IReviewSettings, useReviewSettings, useUpdateReviewSettings, useReviewSettingsConfiguration } from '../../api';
import { Button, TextField } from '@mui/material';
import { RequestState } from '../../api/types';
import { LoadingSpinner } from '../../components/loading-spinner';
import { ValidationErrors } from 'final-form';

export const ReviewSettings: React.FC = () => {
    
    const { data: reviewSettingsConfiguration } = useReviewSettingsConfiguration();
    const { data: reviewSettings, requestState, responseStatusCode } = useReviewSettings();
    const { update, requestState: updateRequestState, responseStatusCode: updateResponseStatusCode } = useUpdateReviewSettings();
    const handleOnSubmit = useCallback((values: any) => {
        const updateReviewSettings: IReviewSettings = {
            overrideWhitelistFields: values.overrideWhitelistFields,
            allowedChecklistOverrideMailAddresses: values.allowedChecklistOverrideMailAddresses
        };
        update(updateReviewSettings)
    }, [update]);

    function checkForInvalidSpecialChars(semicolonSeperatedEmailString: string): Set<string> {
        const validSpecialChars = new Set<string>(['@', '.']); // valid special characters in an email address
        const invalidChars = new Set<string>(); // set to store invalid special characters

        for (let i = 0; i < semicolonSeperatedEmailString.length; i++) {
            const char = semicolonSeperatedEmailString[i];
            if (char !== ';' && !/[a-zA-Z]/.test(char) && !validSpecialChars.has(char)) {
                invalidChars.add(char);
            }
        }

        return invalidChars;
    }

    const onValidate = useCallback((values: any): ValidationErrors => {
        const errors: ValidationErrors = {};

        if (values.allowedChecklistOverrideMailAddresses) {
            const invalidChars = checkForInvalidSpecialChars(values.allowedChecklistOverrideMailAddresses);
            if (invalidChars.size > 0) {
                errors.allowedChecklistOverrideMailAddresses = 'Invalid characters were used: ' + Array.from(invalidChars).join(',');
            }
            
            const addresses = values.allowedChecklistOverrideMailAddresses?.split(";") as undefined | string[] ?? [];
            if (addresses.some(address => !address.endsWith("@juliusbaer.com"))) {
                errors.allowedChecklistOverrideMailAddresses = 'Not all mail addresses end with @juliusbaer.com';
            }
        }

        if (values.overrideWhitelistFields) {

            const fields = values.overrideWhitelistFields?.split(";") as undefined | string[] ?? [];
            
            const illegalFieldsUsed = [] as string[];
            reviewSettingsConfiguration?.neverAllowedFields?.forEach(x => {
                if (fields.indexOf(x.toLowerCase()) > -1) {
                    illegalFieldsUsed.push(x);
                }
            });

            reviewSettingsConfiguration?.alwaysAllowedFields?.forEach(x => {
                if (fields.indexOf(x.toLowerCase()) > -1) {
                    illegalFieldsUsed.push(x);
                }
            })

            if (illegalFieldsUsed.length > 0) {
                errors.overrideWhitelistFields = 
                'Illegal fields were used, please refer to the info text on what fields not to use. \n' +
                'Fields used: ' + illegalFieldsUsed.join(", ") + ".";
            }
        }


        return errors;
    }, [reviewSettingsConfiguration]);

    return (
        <div className="review-settings">
            <PageHeader title="Review" />
            <Form
                onSubmit={handleOnSubmit}
                initialValues={reviewSettings}
                validate={onValidate}
                render={({ handleSubmit, pristine, submitting, form }) => (
                    <form className="settings-form" onSubmit={handleSubmit}>
                        {(requestState === RequestState.IDLE || requestState === RequestState.LOADING) && (
                            <LoadingSpinner />
                        )}
                        {requestState === RequestState.ERROR && (
                            <>
                                {responseStatusCode === 401 && <div className="error">Unauthorized</div>}
                                {responseStatusCode !== 401 && (
                                    <div className="error">
                                        An unexpected error occurred while loading the review settings
                                    </div>
                                )}
                            </>
                        )}
                        {requestState === RequestState.SUCCESS && (
                            <>
                                <h2>Reviewers</h2>
                                <div>
                                    <Field name="allowedChecklistOverrideMailAddresses">
                                        {(props) => (
                                            <TextField
                                                {...props.input}
                                                multiline
                                                rows={4}
                                                label="Reviewers (Semicolon separated E-Mails)"
                                                error={props.meta.error && props.meta.touched}
                                                helperText={props.meta.error && props.meta.touched && props.meta.error}
                                            />
                                        )}
                                    </Field>
                                    <p>
                                        <small>
                                            Info: Only E-Mail Addresses ending with '@juliusbaer.com' are allowed.
                                        </small>
                                    </p>
                                </div>
                                <h2>Whitelisted Fields</h2>
                                <div>
                                    <Field name="overrideWhitelistFields">
                                        {(props) => (
                                            <TextField
                                                multiline
                                                rows={4}
                                                {...props.input}
                                                label="Fields (Semicolon separated Names)"
                                                error={props.meta.error && props.meta.touched}
                                                helperText={props.meta.error && props.meta.touched && props.meta.error}
                                            />
                                        )}
                                    </Field>
                                    <p>
                                        <span className="review-settings__multiple-info-holder">
                                            <small className="review-settings__multiple-info">Info: </small>
                                            <small>
                                                {reviewSettingsConfiguration?.alwaysAllowedFields ? (
                                                    <>
                                                        {reviewSettingsConfiguration.alwaysAllowedFields.join(', ')}
                                                        {' '} <b><i>are always allowed.</i></b><br></br>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                                {reviewSettingsConfiguration?.neverAllowedFields ? (
                                                    <>
                                                        {reviewSettingsConfiguration.neverAllowedFields.join(', ')}
                                                        {' '} <b><i>are never allowed.</i></b>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </small>
                                        </span>
                                    </p>
                                </div>

                                <h2>Save</h2>
                                <div className="review-settings__save">
                                    <Button type="submit" disabled={pristine || submitting}>
                                        Save all Settings
                                    </Button>
                                </div>
                                {updateRequestState === RequestState.LOADING && <LoadingSpinner />}
                                {updateRequestState === RequestState.ERROR && (
                                    <>
                                        {updateResponseStatusCode === 401 && <div className="error">Unauthorized</div>}
                                        {updateResponseStatusCode !== 401 && (
                                            <div className="error">
                                                An unexpected error occurred while saving the settings
                                            </div>
                                        )}
                                    </>
                                )}
                                {updateRequestState === RequestState.SUCCESS && <div>Saved.</div>}
                            </>
                        )}
                    </form>
                )}
            />
        </div>
    );
}