import React, { useCallback } from 'react';
import { ValidationErrors } from 'final-form';
import { Form, Field } from 'react-final-form';
import {
    Button,
    Grid,
    Paper,
    TextField
} from '@mui/material';
import { IMailTemplate } from '../../api';

export interface IMailTemplateFormValues {
    event: string;
    subject: string;
    body: string;
    order: number;
}

interface IMailTemplateFormProps {
    mailTemplate?: IMailTemplate;
    onSubmit: (values: IMailTemplateFormValues) => void;
}

// the component shouldn't be re-rendered when the form is submitted. Therefor this component is memoized
// todo: after editing and submitting an existing mail-template, the pristine state doesn't change. consequence of the memo. should be rethought.
export const MailTemplateForm = React.memo<IMailTemplateFormProps>(({ mailTemplate, onSubmit }) => {
    const onValidate = useCallback((values: any): ValidationErrors => {
        const errors: ValidationErrors = {};

        // required validation checks
        if (!values.event) {
            errors.event = 'Required';
        }
        if (!values.subject) {
            errors.subject = 'Required';
        }
        if (!values.body) {
            errors.body = 'Required';
        }

        return errors;
    }, []);

    let availableSubdocsVariables = `Available variables for subject and body: \${TICKET}`;
    let availableFailedVariables = `Available variables for subject and body: \${REJECT_REASON}`;
    let availableFailedChecklistOverrideVariables = `Available variables for subject and body: \${REJECT_REASON}, \${REJECT_CELLS}`;
    let availableSuccessChecklistOverrideVariables = `Available variables for subject and body: \${SUCCESS_CELLS}`;

    return (
        <Form<IMailTemplateFormValues>
            onSubmit={onSubmit}
            initialValues={{
                ...mailTemplate,
            }}
            validate={onValidate}
            render={({ handleSubmit, pristine, submitting }) => (
                <Paper className="mail-template-form__paper">
                    <form className="mail-template-form" onSubmit={handleSubmit}>
                        <Grid container={true} spacing={2}>
                            { mailTemplate?.event === "SUB_DOCS_ANSWER" &&
                                <Grid item={true} xs={12}>
                                    <p><small>{availableSubdocsVariables}</small></p>
                                </Grid>
                            }
                            { mailTemplate?.event === "FAILED_ANSWER" &&
                                <Grid item={true} xs={12}>
                                    <p><small>{availableFailedVariables}</small></p>
                                </Grid>
                            }
                            { mailTemplate?.event === "SUCCESS_OVERWRITE_ANSWER" &&
                                <Grid item={true} xs={12}>
                                    <p><small>{availableSuccessChecklistOverrideVariables}</small></p>
                                </Grid>
                            }
                            { mailTemplate?.event === "FAILED_OVERWRITE_ANSWER" &&
                                <Grid item={true} xs={12}>
                                    <p><small>{availableFailedChecklistOverrideVariables}</small></p>
                                </Grid>
                            }
                            <Grid item={true} xs={12}>
                                <Field name="subject">
                                    {(props) => (
                                        <TextField
                                            {...props.input}
                                            label="Mail Subject"
                                            error={props.meta.error && props.meta.touched}
                                            helperText={props.meta.error && props.meta.touched && props.meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item={true} xs={12}>
                                <Field name="body">
                                    {(props) => (
                                        <TextField
                                            {...props.input}
                                            multiline={true}
                                            minRows={5}
                                            label="Mail Template (HTML Body)"
                                            error={props.meta.error && props.meta.touched}
                                            helperText={props.meta.error && props.meta.touched && props.meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item={true} xs={12}>
                                <Button type="submit" disabled={pristine || submitting}>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            )}
        />
    );
});
