import React from 'react';
import { NavLink } from 'react-router-dom';
import {
    Article,
    Block,
    Code,
    Home,
    Mail,
    People,
    Public,
    RateReview,
    Settings
} from '@mui/icons-material';
import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Paths } from '../../routing/paths';
import { isDevOrTestOrUatEnv } from '../../utils/env-utils';
import { AccessWrapper } from '../access-wrapper';
import logo from './juliusbaer-logo.svg';

interface INavigationProps {
    version: string;
}

export const Navigation: React.FC<INavigationProps> = ({ version }) => {
    return (
        <Drawer className="nav__drawer" variant="permanent" anchor="left">
            <nav>
                <div className="nav__header">
                    <img className="nav__logo" src={logo} alt="Julius Baer Logo" />
                    <p>PEPOSubDoc</p>
                </div>
                <List className="nav__list">
                    <Divider />
                    <AccessWrapper hideText={true}>
                        <ListItem>
                            <ListItemIcon>
                                <Home />
                            </ListItemIcon>
                            <ListItemText>
                                <NavLink to="/" end={true}>Home</NavLink>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Article />
                            </ListItemIcon>
                            <ListItemText>
                                <NavLink to={Paths.OFFERINGS}>Offerings</NavLink>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Public />
                            </ListItemIcon>
                            <ListItemText>
                                <NavLink to={Paths.COUNTRY_LIST}>Country List</NavLink>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <People />
                            </ListItemIcon>
                            <ListItemText>
                                <NavLink to={Paths.STAFF_LIST}>BJB Staff List</NavLink>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <RateReview />
                            </ListItemIcon>
                            <ListItemText>
                                <NavLink to={Paths.REVIEW_SETTINGS}>Review</NavLink>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Settings />
                            </ListItemIcon>
                            <ListItemText>
                                <NavLink to={Paths.SETTINGS}>Settings</NavLink>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Block />
                            </ListItemIcon>
                            <ListItemText>
                                <NavLink to={Paths.BLACKLIST}>Blacklist</NavLink>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Mail />
                            </ListItemIcon>
                            <ListItemText>
                                <NavLink to={Paths.MAIL_TEMPLATES}>Mail Templates</NavLink>
                            </ListItemText>
                        </ListItem>
                        { isDevOrTestOrUatEnv() &&
                            <ListItem>
                                <ListItemIcon>
                                    <Code/>
                                </ListItemIcon>
                                <ListItemText>
                                    <NavLink to={Paths.DEV}>Dev</NavLink>
                                </ListItemText>
                            </ListItem>
                        }
                    </AccessWrapper>
                </List>
                <div className="nav__version"><small><i>Release version {version}</i></small></div>
            </nav>
        </Drawer>
    );
};
