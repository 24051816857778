import { IOffering } from '../../domain';
import { createOffering } from '../../api-calls';
import { IPostRequestResponse } from '../../types';
import { useAxiosPostRequest } from '../use-axios-request';

interface ICreateOfferingResponse extends IPostRequestResponse<IOffering> {}

export const useCreateOffering = (): ICreateOfferingResponse => {
    return useAxiosPostRequest<IOffering>(createOffering);
};
