import {
    uploadAdditionalStaffList,
    uploadBJBStaffList,
    uploadChecklistOverride,
    uploadFiles,
    uploadReviewers
} from '../../api-calls';
import { IPostRequestResponse } from '../../types';
import { useAxiosPostRequest } from '../use-axios-request';

interface IFileUploadResponse extends IPostRequestResponse<FormData, void> {}

export const useFileUpload = (): IFileUploadResponse => {
    return useAxiosPostRequest<FormData, void>(uploadFiles);
};

export const useAdditionalStaffListUpload = (): IFileUploadResponse => {
    return useAxiosPostRequest<FormData, void>(uploadAdditionalStaffList);
};

export const useBJBStaffListUpload = (): IFileUploadResponse => {
    return useAxiosPostRequest<FormData, void>(uploadBJBStaffList);
};

export const useChecklistOverrideUpload = (): IFileUploadResponse => {
    return useAxiosPostRequest<FormData, void>(uploadChecklistOverride);
};

export const useReviewersUpload = (): IFileUploadResponse => {
    return useAxiosPostRequest<FormData, void>(uploadReviewers);
};
