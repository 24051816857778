import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IOfferingFormValues, OfferingForm } from '../../components/offering-form';
import { IOffering, useOffering, useUpdateOffering } from '../../api';
import { RequestState } from '../../api/types';
import { LoadingSpinner } from '../../components/loading-spinner';
import { PageHeader } from '../../components/page-header';
import { Paths } from '../../routing/paths';

type OfferingDetailsParams = {
    id: string;
};

export const OfferingEdit: React.FC = () => {
    const navigate = useNavigate();

    const { id } = useParams<OfferingDetailsParams>();
    const { requestState: getRequestState, data: offering, responseStatusCode } = useOffering(id ?? '');
    const { requestState: updateRequestState, update: updateOffering, responseStatusCode: updateResponseStatusCode } = useUpdateOffering();

    useEffect(() => {
        if(updateRequestState === RequestState.SUCCESS) {
            navigate(Paths.OFFERINGS);
        }
    }, [updateRequestState, navigate]);

    const editOffering = useCallback(
        (values: IOfferingFormValues) => {
            const editedOffering: IOffering = {
                id: id!,
                fundName: values.fundName,
                fundShortName: values.fundShortName,
                sortId: values.sortId,
                closingDate: values.closingDate?.startOf('day')?.toISO() ?? '',
                capacity: values.capacity,
                currency: values.currency,
                reserve: values.reserve,
                isActive: values.isActive,
                type: values.type,
                subject: values.subject,
                htmlBody: values.htmlBody,
            };

            if(!editedOffering.isActive) {
                // eslint-disable-next-line no-restricted-globals
                if (confirm("The inactivation of an offering results in the anonymization of client data and is irrevocable. Are you sure that you want to continue?")) {
                    updateOffering(editedOffering);
                }
            } else {
                updateOffering(editedOffering);
            }
        },
        [id, updateOffering]
    );

    const header = <PageHeader title="Edit Offering" hasBackNavigation={true} />;

    if (getRequestState === RequestState.ERROR || !id) {
        return (
            <div>
                {header}
                <>
                    { responseStatusCode === 401 && <p className="error">Unauthorized</p> }
                    { responseStatusCode !== 401 && <p className="error">An unexpected error occurred.</p> }
                </>
            </div>
        );
    } else if (getRequestState === RequestState.LOADING) {
        return (
            <div>
                {header}
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <div>
            {header}
            <OfferingForm offering={offering} onSubmit={editOffering} />
            <div>
                {updateRequestState === RequestState.LOADING && (
                    <>
                        <span>Submitting...</span>
                        <LoadingSpinner />
                    </>
                )}
                {updateRequestState === RequestState.ERROR && (
                    <>
                        { updateResponseStatusCode === 401 && <p className="error">Unauthorized</p> }
                        { updateResponseStatusCode !== 401 && <p className="error">An unexpected error occurred while submitting.</p> }
                    </>
                )}
                {updateRequestState === RequestState.SUCCESS && <p>Submitted data successfully</p>}
            </div>
        </div>
    );
};
