import React, { useEffect } from 'react';
import { SigninRedirectArgs } from 'oidc-client-ts';

interface IRedirectToIdp {
    signinRedirect: (args?: SigninRedirectArgs) => Promise<void>;
}

/**
 * A Component that redirects to the signing page of the idp
 *
 * @param signinRedirect Returns promise to trigger a redirect of the current window to the authorization endpoint.
 */
export const LoginRedirectToIdp: React.FC<IRedirectToIdp> = ({ signinRedirect }) => {
    useEffect(() => {
        signinRedirect();
    }, [signinRedirect]);

    return <div>Redirect to Login...</div>;
};
