import axios, { AxiosResponse } from 'axios';
import { generatePath } from 'react-router-dom';
import { IRequestOptions } from '../types';
import { ISettings } from '../domain';

enum Url {
    SETTINGS = '/settings',
}

export const fetchSettings: (options?: IRequestOptions<ISettings>) => Promise<AxiosResponse<ISettings>> = (options) => {
    return axios.get<ISettings>(generatePath(Url.SETTINGS));
};

export const updateSettings: (settings: ISettings, options?: IRequestOptions) => Promise<AxiosResponse<ISettings>> = (
    settings
) => {
    return axios.put<ISettings>(Url.SETTINGS, settings);
};