export const jbColors = {
    // Core palette
    reflexBlue: {
        '100': '#001489',
        '80': '#3343A1',
        '60': '#6672B8',
        '40': '#99A1D0',
        '20': '#CCD0E7',
    },
    stone: {
        '100': '#B0AA7E',
        '80': '#C0BB98',
        '60': '#D0CCB2',
        '40': '#DFDDCB',
        '20': '#EFEEE5',
    },
    black: {
        '100': '#000000',
        '80': '#333333',
        '60': '#666666',
        '40': '#999999',
        '20': '#CCCCCC',
    },
    // Support palette
    royalBlue: {
        '100': '#141e55',
        '80': '#434b77',
        '70': '#5B6188',
        '60': '#727899',
        '40': '#a1a5bb',
        '20': '#d0d2dd',
    },
    greenSmoke: {
        '100': '#717c7d',
        '80': '#8d9697',
        '60': '#aab0b1',
        '40': '#c6cbcb',
        '20': '#e3e5e5',
    },
    violetGrey: {
        '100': '#7b6469',
        '80': '#958387',
        '60': '#b0a2a5',
        '40': '#cac1c3',
        '20': '#e5e0e1',
    },
    // Technical palette
    persianBlue: {
        '100': '#0080a3',
        '80': '#3399b5',
        '60': '#66b3c8',
        '40': '#99ccda',
        '20': '#cce5ed',
    },
    mulberryPurple: {
        '100': '#6e2b62',
        '80': '#8b5581',
        '60': '#a880a1',
        '40': '#c5aac0',
        '20': '#e2d5e0',
    },
    carmineRed: {
        '100': '#971b2f',
        '90': '#A13244',
        '80': '#ac495a',
        '60': '#c17683',
        '50': '#cb8d97',

        '40': '#d5a4ac',
        '20': '#ead1d5',
        '10': '#F5E8EA',
    },
    red: {
        '100': '#ff3333',
    },
    forestGreen: {
        '100': '#008675',
        '90': '#199283',
        '80': '#339e92',
        '60': '#66b6ad',
        '50': '#80C2BA',
        '40': '#99cfc8',
        '20': '#cce7e3',
        '10': '#e5f3f1',
    },
    amberYellow: {
        '100': '#cc8b00',
        '80': '#d6a133',
        '60': '#e0b966',
        '40': '#ebd099',
        '20': '#f5e8cc',
    },
};
