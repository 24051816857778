import { IReviewSettings } from '../../domain';
import { IPutRequestResponse } from '../../types';
import { updateReviewSettings } from '../../api-calls';
import { useAxiosPutRequest } from '../use-axios-request';

interface IUpdateReviewSettingsResponse extends IPutRequestResponse<IReviewSettings> {}

export const useUpdateReviewSettings = (): IUpdateReviewSettingsResponse => {
    return useAxiosPutRequest<IReviewSettings>(updateReviewSettings);
};
