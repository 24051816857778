import React, { useEffect } from 'react';
import axios from 'axios';
import { useAuth } from 'react-oidc-context';
import { NavigateFunction, useNavigate } from 'react-router-dom';

export const LoginCallback: React.FC = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const token = auth.user?.access_token;

    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    useEffect(() => {
        const navigateToHome = async (navigate: NavigateFunction) => {
            navigate('/', { replace: true });
        };

        navigateToHome(navigate);
    }, [navigate]);

    return <div>Redirecting...</div>;
};
