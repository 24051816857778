import { IConfig } from '../../domain';
import { IGetRequestResponse } from '../../types';
import { fetchConfig } from '../../api-calls';
import { useAxiosGetRequest } from '../use-axios-request';

interface IConfigResponse extends IGetRequestResponse<IConfig> {}

export const useConfig = (): IConfigResponse => {
    return useAxiosGetRequest<IConfig>(fetchConfig);
};
