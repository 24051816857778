import axios, { AxiosResponse } from 'axios';
import { generatePath } from 'react-router-dom';
import { IRequestOptions } from '../types';
import { IStaffListUpdate } from '../domain';

enum Url {
    STAFF_LIST_UPDATES = '/staff-list-update',
}

export const fetchStaffListUpdates: (options?: IRequestOptions<IStaffListUpdate>) => Promise<AxiosResponse<IStaffListUpdate>> = (options) => {
    return axios.get<IStaffListUpdate>(generatePath(Url.STAFF_LIST_UPDATES));
};