export enum Paths {
    BLACKLIST = '/blacklist',
    COUNTRY_LIST = '/country-list',
    DEV = '/dev',
    LOGIN_CALLBACK = 'login-callback',
    MAIL_TEMPLATES = '/mail-templates',
    MAIL_TEMPLATE_EDIT = '/mail-templates/:id',
    OFFERINGS = '/offerings',
    OFFERING_EDIT = '/offerings/:id',
    OFFERING_NEW = '/offerings/new',
    REVIEW_SETTINGS = '/review-settings',
    SETTINGS = '/settings',
    STAFF_LIST = '/staff-list',
}
