import { createTheme } from '@mui/material';
import { palette } from './palette';
import { typography } from './typography';
import { components } from './components';

/**
 * based on https://bitbucket.sehlat.io/projects/PLATFORM/repos/jb-react-components-library
 */
export const theme = createTheme({
    components,
    palette,
    typography,
});
