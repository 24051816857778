import React, { useCallback } from 'react';
import { FileUpload } from '../../components/file-upload';
import { DateTime } from 'luxon';
import { useAdditionalStaffListUpload, useBJBStaffListUpload, useStaffListUpdates } from '../../api';
import { RequestState } from '../../api/types';
import { LoadingSpinner } from '../../components/loading-spinner';
import { formatDate, formatIsoDate } from '../../utils';

export const StaffList: React.FC = () => {
    const { requestState: staffListUpdateRequestState, data: staffListUpdate } = useStaffListUpdates();
    const { requestState: staffListRequestState, create: staffListUpload, responseStatusCode: staffListUploadResponseStatusCode } = useBJBStaffListUpload();
    const { requestState: additionalStaffListRequestState, create: additionalStaffListUpload, responseStatusCode: additionalStaffListUploadResponseStatusCode } = useAdditionalStaffListUpload();

    const onStaffListUpload = useCallback((files: File[]) => {
        // https://docs.microsoft.com/en-us/aspnet/core/mvc/models/file-uploads?view=aspnetcore-6.0#match-name-attribute-value-to-parameter-name-of-post-method
        const formData = new FormData();

        files.forEach((file) => {
            formData.append('files', file, file.name);
        });

        staffListUpload(formData);
    }, [staffListUpload]);

    const onChecklistOverrideUpload = useCallback((files: File[]) => {
        // https://docs.microsoft.com/en-us/aspnet/core/mvc/models/file-uploads?view=aspnetcore-6.0#match-name-attribute-value-to-parameter-name-of-post-method
        const formData = new FormData();

        files.forEach((file) => {
            formData.append('files', file, file.name);
        });

        additionalStaffListUpload(formData);
    }, [additionalStaffListUpload]);


    const bjbStaffListUpdate = staffListUpdate?.latestBJBStaffListUpdate ? formatIsoDate(staffListUpdate.latestBJBStaffListUpdate, DateTime.DATETIME_SHORT, { locale: 'de' }) : '';
    const latestBJBStaffListUpdate = staffListRequestState === RequestState.SUCCESS ? formatDate(DateTime.now(), DateTime.DATETIME_SHORT, { locale: 'de' }) : bjbStaffListUpdate;

    const additionalStaffListUpdate = staffListUpdate?.latestAdditionalStaffListUpdate ? formatIsoDate(staffListUpdate.latestAdditionalStaffListUpdate, DateTime.DATETIME_SHORT, { locale: 'de' }) : '';
    const latestAdditionalStaffListUpdate = staffListUpdateRequestState === RequestState.SUCCESS ? formatDate(DateTime.now(), DateTime.DATETIME_SHORT, { locale: 'de' }) : additionalStaffListUpdate;

    return (
        <div className="staff-list">
            <h2>BJB Staff List</h2>
            <small>A1 to A8 must be: LastName | FirstName | Email | DivisionRegion | SubdivisionMarket | Function | Location | Country</small>
            {/* todo: these values should be defined in BE; 10485760 bytes = 10 MB*/}
            <FileUpload
                accept={[".xls",".xlsx"]}
                disabled={staffListRequestState === RequestState.LOADING}
                maxSize={10485760}
                singleFile={true}
                onUpload={onStaffListUpload}
            />
            <div>
                {staffListRequestState === RequestState.LOADING && (
                    <>
                        <span>Uploading files...</span>
                        <LoadingSpinner />
                    </>
                )}
                {staffListRequestState === RequestState.ERROR && (
                    <>
                        { staffListUploadResponseStatusCode === 401 && <p className="error">Unauthorized</p> }
                        { staffListUploadResponseStatusCode !== 401 && <p className="error">An unexpected error occurred while uploading files.</p> }
                    </>
                )}
                {staffListRequestState === RequestState.SUCCESS && <p>Uploaded files successfully</p>}
            </div>
            <div>
                {
                    staffListUpdateRequestState === RequestState.SUCCESS &&
                    <p>Last updated: { latestBJBStaffListUpdate }</p>
                }
            </div>

            <h2>Additional Emails</h2>
            {/* todo: these values should be defined in BE; 10485760 bytes = 10 MB*/}
            <FileUpload
                accept={[".xls", ".xlsx"]}
                disabled={additionalStaffListRequestState === RequestState.LOADING}
                maxSize={10485760}
                singleFile={true}
                onUpload={onChecklistOverrideUpload}
            />
            <div>
                {additionalStaffListRequestState === RequestState.LOADING && (
                    <>
                        <span>Uploading...</span>
                        <LoadingSpinner />
                    </>
                )}
                {additionalStaffListRequestState === RequestState.ERROR && (
                    <>
                        { additionalStaffListUploadResponseStatusCode === 401 && <p className="error">Unauthorized</p> }
                        { additionalStaffListUploadResponseStatusCode !== 401 && <p className="error">An unexpected error occurred while uploading.</p> }
                    </>
                )}
                {additionalStaffListRequestState === RequestState.SUCCESS && <p>Uploaded successfully</p>}
            </div>
            <div>
                {
                    staffListUpdateRequestState === RequestState.SUCCESS &&
                    <p>Last updated: { latestAdditionalStaffListUpdate }</p>
                }
            </div>
        </div>
    );
};
