import axios, { AxiosResponse } from 'axios';
import { generatePath } from 'react-router-dom';
import { IMailTemplate } from '../domain';
import { IRequestOptions } from '../types';


enum Url {
    MAIL_TEMPLATE = '/mail-template/:id',
    MAIL_TEMPLATES = '/mail-template',
}

export const fetchMailTemplate: (options?: IRequestOptions<IMailTemplate>) => Promise<AxiosResponse<IMailTemplate>> = (options) => {
    return axios.get<IMailTemplate>(generatePath(Url.MAIL_TEMPLATE, { id: options?.data?.id ?? '' }));
}

export const fetchMailTemplates: () => Promise<AxiosResponse<IMailTemplate[]>> = () => {
    return axios.get<IMailTemplate[]>(Url.MAIL_TEMPLATES);
}

export const updateMailTemplate: (mailTemplate: IMailTemplate, options?: IRequestOptions) => Promise<AxiosResponse<IMailTemplate>> = (mailTemplate) => {
    return axios.put<IMailTemplate>(Url.MAIL_TEMPLATES, mailTemplate);
}