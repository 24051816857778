import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IOfferingFormValues, OfferingForm } from '../../components/offering-form';
import { LoadingSpinner } from '../../components/loading-spinner';
import { IOffering, useCreateOffering } from '../../api';
import { RequestState } from '../../api/types';
import { PageHeader } from '../../components/page-header';
import { Paths } from '../../routing/paths';

export const OfferingCreate: React.FC = () => {
    const navigate = useNavigate();

    const { create: createOffering, requestState, responseStatusCode } = useCreateOffering();

    useEffect(() => {
        if(requestState === RequestState.SUCCESS) {
            navigate(Paths.OFFERINGS);
        }
    }, [requestState, navigate]);

    const createNewOffering = useCallback(
        (values: IOfferingFormValues) => {
            const offering: Partial<IOffering> = {
                fundName: values.fundName,
                fundShortName: values.fundShortName,
                sortId: values.sortId,
                closingDate: values.closingDate?.startOf('day')?.toISO() ?? undefined,
                capacity: values.capacity,
                currency: values.currency,
                reserve: values.reserve,
                isActive: values.isActive,
                type: values.type,
                subject: values.subject,
                htmlBody: values.htmlBody,
            };

            createOffering(offering);
        },
        [createOffering]
    );

    return (
        <div className="offering-create">
            <PageHeader title="Create new Offering" hasBackNavigation={true} />
            <OfferingForm onSubmit={createNewOffering} />
            <div>
                {requestState === RequestState.LOADING && (
                    <>
                        <span>Submitting...</span>
                        <LoadingSpinner />
                    </>
                )}
                {requestState === RequestState.ERROR && (
                    <>
                        { responseStatusCode === 401 && <p className="error">Unauthorized</p> }
                        { responseStatusCode !== 401 && <p className="error">An unexpected error occurred while submitting.</p> }
                    </>
                )}
                {requestState === RequestState.SUCCESS && <p>Submitted data successfully</p>}
            </div>
        </div>
    );
};
