import React from 'react';
import { OfferingsTable } from '../../components/offerings-table';
import { PageHeader } from '../../components/page-header';

export const Home: React.FC = () => {
    return (
        <div className="home">
            <PageHeader title="Home" />
            <h2>Offerings</h2>
            <OfferingsTable hideInactive={true} />
        </div>
    );
};
