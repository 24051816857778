import { IOffering } from '../../domain';
import { IGetRequestResponse } from '../../types';
import { fetchOfferings } from '../../api-calls';
import { useAxiosGetRequest } from '../use-axios-request';

interface IOfferingsResponse extends IGetRequestResponse<IOffering[]> {}

export const useOfferings = (): IOfferingsResponse => {
    return useAxiosGetRequest<IOffering[]>(fetchOfferings);
};
