import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IMailTemplate, useMailTemplate, useUpdateMailTemplate } from '../../api';
import { useCallback, useEffect } from 'react';
import { RequestState } from '../../api/types';
import { Paths } from '../../routing/paths';
import { PageHeader } from '../../components/page-header';
import { LoadingSpinner } from '../../components/loading-spinner';
import { IMailTemplateFormValues, MailTemplateForm } from '../../components/mail-template-form';

type MailTemplateDetailsParams = {
    id: string;
};

export const MailTemplateEdit: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams<MailTemplateDetailsParams>();

    const { requestState: getRequestState, data: mailTemplate, responseStatusCode } = useMailTemplate(id ?? '');
    const { requestState: updateRequestState, update: updateMailTemplate, responseStatusCode: updateResponseStatusCode } = useUpdateMailTemplate();

    useEffect(() => {
        if(updateRequestState === RequestState.SUCCESS) {
            navigate(Paths.MAIL_TEMPLATES);
        }
    }, [updateRequestState, navigate]);

    const editMailTemplate = useCallback(
        (values: IMailTemplateFormValues) => {
            const editedMailTemplate: IMailTemplate = {
                id: id!,
                'event': values.event,
                subject: values.subject,
                body: values.body,
                order: mailTemplate?.order ?? 0,
            };

            updateMailTemplate(editedMailTemplate);
        },
        [id, mailTemplate, updateMailTemplate]
    );

    const header = <PageHeader title={`Edit Mail Template ${mailTemplate?.event ?? ''}`} hasBackNavigation={true} />;

    if (getRequestState === RequestState.ERROR || !id) {
        return (
            <div>
                {header}
                <>
                    { responseStatusCode === 401 && <p className="error">Unauthorized</p> }
                    { responseStatusCode !== 401 && <p className="error">An unexpected error occurred.</p> }
                </>
            </div>
        );
    } else if (getRequestState === RequestState.LOADING) {
        return (
            <div>
                {header}
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <div>
            {header}
            <MailTemplateForm mailTemplate={mailTemplate} onSubmit={editMailTemplate} />
            <div>
                {updateRequestState === RequestState.LOADING && (
                    <>
                        <span>Submitting...</span>
                        <LoadingSpinner />
                    </>
                )}
                {updateRequestState === RequestState.ERROR && (
                    <>
                        { updateResponseStatusCode === 401 && <p className="error">Unauthorized</p> }
                        { updateResponseStatusCode !== 401 && <p className="error">An unexpected error occurred while submitting.</p> }
                    </>
                )}
                {updateRequestState === RequestState.SUCCESS && <p>Submitted data successfully</p>}
            </div>
        </div>
    );
}