import React, { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { PageHeader } from '../../components/page-header';

import { ICountryList, useCountryList, useUpdateCountryList } from '../../api';
import { Button, Grid, TextField } from '@mui/material';
import { RequestState } from '../../api/types';
import { LoadingSpinner } from '../../components/loading-spinner';
import { ValidationErrors } from 'final-form';
import { formatDate } from '../../utils';
import { DateTime } from 'luxon';

export const CountryList: React.FC = () => {
    
    const { data: countryList, requestState, responseStatusCode } = useCountryList();
    const { update, requestState: updateRequestState, responseStatusCode: updateResponseStatusCode } = useUpdateCountryList();
    const handleOnSubmit = useCallback((values: any) => {
        const updateCountryList: ICountryList = {
            countryList: values.countryList,
        };
        update(updateCountryList)
    }, [update]);

    function checkForInvalidSpecialChars(semicolonSeperatedEmailString: string): string[] {
        const validSpecialChars = [';', ' ', '-', '\'']; // valid special characters in an email address
        const invalidChars: string[] = []; // set to store invalid special characters

        for (let i = 0; i < semicolonSeperatedEmailString.length; i++) {
            const char = semicolonSeperatedEmailString[i];
            if (char !== ';' && !/[a-zA-Z]/.test(char) && !(validSpecialChars.indexOf(char) > -1) && invalidChars.indexOf(char) === -1) {
                invalidChars.push(char);
            }
        }

        return invalidChars;
    }

    const onValidate = useCallback((values: any): ValidationErrors => {
        const errors: ValidationErrors = {};


        if (values.countryList) {
            const fields = values.countryList?.split(";") as undefined | string[] ?? [];
            
            fields.forEach(element => {
                let invalidChars = checkForInvalidSpecialChars(element);

                if (invalidChars.length > 0) {
                    if (errors.countryList) { 
                        errors.countryList += ` ${element} has invalid characters: ${invalidChars.join()}.`;
                    }
                    else {
                        errors.countryList = `${element} has invalid characters: ${invalidChars.join()}.`
                    }
                }
            });
        }


        return errors;
    }, []);

    return (
        <div className="country-list">
            <PageHeader title="Country" />
            <Form
                onSubmit={handleOnSubmit}
                initialValues={countryList}
                validate={onValidate}
                render={({ handleSubmit, pristine, submitting, form }) => (
                    <form className="list-form" onSubmit={handleSubmit}>
                        <Grid container={true}>
                        {(requestState === RequestState.IDLE || requestState === RequestState.LOADING) && (
                            <LoadingSpinner />
                        )}
                        {requestState === RequestState.ERROR && (
                            <>
                                {responseStatusCode === 401 && <div className="error">Unauthorized</div>}
                                {responseStatusCode !== 401 && (
                                    <div className="error">
                                        An unexpected error occurred while loading the country list
                                    </div>
                                )}
                            </>
                        )}
                        {requestState === RequestState.SUCCESS && (
                            <>
                                <Grid item={true} xs={6}><h2>Country List</h2></Grid>
                                <Grid item={true} lg={12} maxWidth={1000}>
                                    <Field name="countryList">
                                        {(props) => (
                                            <TextField
                                                multiline
                                                rows={20}
                                                className="country-list__inputfield"
                                                {...props.input}
                                                label="Countries (Semicolon separated)"
                                                error={props.meta.error && props.meta.touched}
                                                helperText={props.meta.error && props.meta.touched && props.meta.error}
                                            />
                                        )}
                                    </Field>
                                    <p>
                                        <small>
                                            Allowed Specials Characters: ' -
                                        </small>
                                    </p>
                                </Grid>

                                <Grid item={true} xs={6}><h2>Save</h2></Grid>
                                <Grid item={true} xs={12}>
                                <div className="country-list__save">
                                    <Button type="submit" disabled={pristine || submitting}>
                                        Save Countries
                                    </Button>
                                </div>
                                <br></br>
                                {updateRequestState === RequestState.LOADING && <LoadingSpinner />}
                                {updateRequestState === RequestState.ERROR && (
                                    <>
                                        {updateResponseStatusCode === 401 && <div className="error">Unauthorized</div>}
                                        {updateResponseStatusCode !== 401 && (
                                            <div className="error">
                                                An unexpected error occurred while saving the list
                                            </div>
                                        )}
                                    </>
                                )}
                                {updateRequestState === RequestState.SUCCESS && <div>Saved.</div>}
                                

                                <p>Last Updated: {countryList?.lastUpdated ? formatDate(DateTime.fromISO(countryList!.lastUpdated!)) : '-'}</p> 

                                </Grid>
                            </>
                        )}</Grid>
                    </form>
                )}
            />
        </div>
    );
}