import axios, { AxiosResponse } from 'axios';
import { generatePath } from 'react-router-dom';
import { IConfig } from '../domain';
import { IRequestOptions } from '../types';

enum Url {
    CONFIG = '/config',
    HAS_ACCESS = '/config/has-access',
}

export const fetchConfig: (options?: IRequestOptions<IConfig>) => Promise<AxiosResponse<IConfig>> = (options) => {
    return axios.get<IConfig>(generatePath(Url.CONFIG));
};

export const hasAccess: (options?: IRequestOptions<void>) => Promise<AxiosResponse<void>> = (options) => {
    return axios.get<void>(generatePath(Url.HAS_ACCESS));
}