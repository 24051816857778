import { ISettings } from '../../domain';
import { IGetRequestResponse } from '../../types';
import { fetchSettings } from '../../api-calls';
import { useAxiosGetRequest } from '../use-axios-request';

interface ISettingsResponse extends IGetRequestResponse<ISettings> {}

export const useSettings = (): ISettingsResponse => {
    return useAxiosGetRequest<ISettings>(fetchSettings);
};
