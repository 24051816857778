import axios, { AxiosResponse } from 'axios';
import { generatePath } from 'react-router-dom';
import { IRequestOptions } from '../types';

enum Url {
    EWS = '/ews',
    READ_MAILS = '/ews/read-mails',
    RESET_IS_READING = '/ews/reset-is-reading',
}

export const readMails: (options?: IRequestOptions) => Promise<AxiosResponse<any>> = (options) => {
    return axios.post(generatePath(Url.READ_MAILS));
}

export const resetIsReading: (options?: IRequestOptions) => Promise<AxiosResponse<any>> = (options) => {
    return axios.post(generatePath(Url.RESET_IS_READING));
}